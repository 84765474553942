export const testEmailRegExp = (str) => {
  /* eslint-disable */
  const emailRegExp =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  /* eslint-enable */
  return emailRegExp.test(str);
};

export const testPhoneRegExp = (str) => {
  const phoneRegExp = /^[0-9]*$/;
  return phoneRegExp.test(str);
};

export const testNameRegExp = (str) => {
  const nameLength = str.replace(/[^\x00-\xff]/g, "xx").length;
  return nameLength < 64;
};

export const containsComma = (str) => {
  const commaRegExp = /[,]/;
  return commaRegExp.test(str);
};
