import { useState, useEffect } from "react";
import { Marquee } from "..";
import { useInterval } from "../../util";
import { client } from "../../client";
import { FIRST_NEWS_DEFAULT } from "../../constant";

const NewsBanner = () => {
  const [urlIndex, setUrlIndex] = useState(0);
  const [firstNews, setFirstNews] = useState(FIRST_NEWS_DEFAULT);
  useEffect(() => {
    const fetchData = async () => {
      const firstNewsRes = await client.getEntry("4rnE6TQKnh63EKzG2tujeN");
      if (firstNewsRes) {
        setFirstNews(firstNewsRes.fields.firstNews);
        setFirstNews(firstNewsRes.fields.firstNews);
        setFirstNews(firstNewsRes.fields.firstNews);
      }
    };
    try {
      fetchData();
    } catch {
      setFirstNews(FIRST_NEWS_DEFAULT);
    }
  }, []);

  useInterval(
    async () => {
      const nextUrlIndex = (urlIndex + 1) % firstNews.imgUrls.length;
      setUrlIndex(nextUrlIndex);
    },
    5000 // pause the interval by passing null
  );
  return (
    <div className="news-banner overflow-hidden flex justify-center items-center">
      <div
        className="news-tag"
        style={{
          background: `center/cover url('/img/home/news_tag.png')`,
        }}
      >
        <div className="mr-3 overflow-hidden">
          <Marquee width="3000px">
            <span className="news-title">{firstNews.title}</span>
          </Marquee>
        </div>
        <p>
          {firstNews.desc} <br />
          <br />
        </p>
      </div>
      <img className="w-3/5" src={firstNews.imgUrls[urlIndex]} alt="" />
    </div>
  );
};
export default NewsBanner;
