import {
  pcControllerWidth,
  pcControllerHeight,
  mobileControllerWidth,
  mobileControllerHeight,
  pcMonitorWidth,
  pcMonitorHeight,
  mobileMonitorWidth,
  mobileMonitorHeight,
  pcControllerVideoWidth,
  pcControllerVideoHeight,
  mobileControllerVideoWidth,
  mobileControllerVideoHeight,
  pcMonitorVideoWidth,
  pcMonitorVideoHeight,
  mobileMonitorVideoWidth,
  mobileMonitorVideoHeight,
} from "../../constant";

const VideoController = ({
  title,
  videoId,
  isPCView,
  setVideoIndex,
  index,
  desc,
  margin,
}) => {
  const onClick = () => {
    setVideoIndex(index);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      onClick={onClick}
      style={{
        height: isPCView
          ? `${pcControllerHeight}px`
          : `${mobileControllerHeight}px`,
        width: isPCView
          ? `${pcControllerWidth}px`
          : `${mobileControllerWidth}px`,
        backgroundImage: `url('/img/video/controller_bg.svg')`,
      }}
      className="video-controller relative w-96 text-black cursor-pointer z-10"
    >
      <div className="flex justify-center">
        <img
          style={{
            borderRadius: isPCView ? "1.5rem" : "0.5rem",
            width: isPCView
              ? `${pcControllerVideoWidth}px`
              : `${mobileControllerVideoWidth}px`,
            height: isPCView
              ? `${pcControllerVideoHeight}px`
              : `${mobileControllerVideoHeight}px`,
          }}
          className="mt-2 sm:mt-4 mb-2"
          src={`https://img.youtube.com/vi/${videoId}/0.jpg
`}
          alt=""
        />
      </div>
      <div className="relative flex mx-1 sm:m-4 py-2 px-6 rounded border-double border-4 border-gray-300 text-xs sm:text-2xl">
        {isPCView && (
          <img
            className="controller-title-deco"
            src="/img/video/left_circle.svg"
            alt=""
          />
        )}
        {title}
        {isPCView && (
          <img
            className="controller-title-deco"
            src="/img/video/right_circle.svg"
            alt=""
          />
        )}
      </div>
      <div className="mx-4 mt-2">
        <img src="/img/video/controller_deco.svg" alt="" />
        {isPCView && <div className="controller-desc ml-4">{desc}</div>}
        {isPCView && <br />}
      </div>
      <div
        className="controller-small-screen flex justify-center"
        style={{
          height: isPCView
            ? `${pcMonitorHeight}px`
            : `${mobileMonitorHeight}px`,
          width: isPCView ? `${pcMonitorWidth}px` : `${mobileMonitorWidth}px`,
          background: `url('/img/video/controller_monitor_bg3.svg') no-repeat`,
          left: isPCView ? "35px" : "10px",
          bottom: isPCView ? "24px" : "40px",
        }}
      >
        <div
          className="controller-small-image"
          style={{
            width: isPCView
              ? `${pcMonitorVideoWidth}px`
              : `${mobileMonitorVideoWidth}px`,
            height: isPCView
              ? `${pcMonitorVideoHeight}px`
              : `${mobileMonitorVideoHeight}px`,
            background: `url("https://img.youtube.com/vi/${videoId}/1.jpg") center/cover no-repeat`,
          }}
        />
      </div>
      <img
        className="controller-button"
        style={{
          width: isPCView ? "60px" : "22px",
          bottom: isPCView ? "50px" : "40px",
        }}
        src="/img/video/controller_buttons.svg"
        alt=""
      />
    </div>
  );
};

export default VideoController;
