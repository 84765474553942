import { Vector3, AnimationMixer, AnimationUtils } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Instrument } from "./instrument";
import { instrumentsInfo1 } from "../../constant";
import { isMobile } from "react-device-detect";

const setting = {
  position: new Vector3(-4, -0.5, 7),
  scale: isMobile ? new Vector3(5, 5, 5) : new Vector3(8, 8, 8),
};

class Instrument1 extends Instrument {
  constructor() {
    super(1, ["instrument1", "instrument2", "instrument3", "instrument4"]);
    this.instrumentsInfo = instrumentsInfo1;
  }

  load(scene) {
    const loader = new GLTFLoader();
    loader.load(`/models/instruments/instrument${this.id}.glb`, (gltf) => {
      this.gltf = gltf;
      this.gltf.scene.traverse((child) => {
        if (child.name === "Button_range" || child.name === "Poses__body") {
          child.visible = false;
        }
        if (child.name === "glass") {
          child.material.opacity = 0;
        }
        if (child.name === "body_1") {
          this.body = child;
        }
      });
      this.gltf.scene.name = `Instrument_model${this.id}`;
      this.gltf.scene.scale.copy(setting.scale);
      this.animationMixer = new AnimationMixer(this.body);
      this.animations = this.gltf.animations[0];
      this.objects = this.gltf.scene.children[0].children;
      this.setAxisCenter(this.box, this.gltf.scene);

      scene.add(this.gltf.scene);
    });
  }

  click() {
    if (!this.gltf) return;
    const intersects = this.raycaster.intersectObjects(this.objects, true);
    if (intersects.length) {
      const name = intersects[0].object.name;
      if (this.instrumentsInfo[name]) {
        this.target = name;
        this.playSound();
        this.playAnimation();
      }
    }
  }

  hover() {
    if (!this.gltf) return;
    const intersects = this.raycaster.intersectObjects(this.objects, true);
    if (intersects.length) {
      const name = intersects[0].object.name;
      if (this.instrumentsInfo[name]) {
        document.body.style.cursor = "pointer";
      } else {
        document.body.style.cursor = "auto";
      }
    }
  }

  animate({ start, end, clipName, loopMode, clampWhenFinished }) {
    const clip = AnimationUtils.subclip(this.animations, clipName, start, end);
    this.animationAction = this.animationMixer.clipAction(clip);
    this.animationAction.setLoop(loopMode);
    this.animationAction.clampWhenFinished = clampWhenFinished;

    this.animationAction.play();
  }

  playAnimation() {
    const clip = this.instrumentsInfo[this.target]?.clips?.click;
    if (clip) this.animate(clip);
  }

  stop() {
    if (this.animationAction) {
      const releaseClip = this.instrumentsInfo[this.target]?.clips?.release;
      if (releaseClip) {
        this.animate(releaseClip);
        setTimeout(() => this.stopAnimation(), 400);
      } else {
        this.stopAnimation();
      }
    }
    this.stopSound();
    this.target = null;
  }
}

export default Instrument1;
