import { useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  isMenuOpenState,
  isLoginModalOpenState,
  isProfileModalOpenState,
} from "../../atom";
import { getToken, useTheme } from "../../util";

const MenuModal = () => {
  const { colorMode } = useTheme();
  const isLogin = !!getToken();

  const setIsLoginModalOpen = useSetRecoilState(isLoginModalOpenState);
  const setIsProfileModalOpen = useSetRecoilState(isProfileModalOpenState);
  const [isMenuModalOpen, setIsMenuModalOpen] = useRecoilState(isMenuOpenState);
  const location = useLocation();
  useEffect(() => {
    setIsMenuModalOpen(false);
  }, [setIsMenuModalOpen, location]);

  const history = useHistory();
  const onVipClick = useCallback(() => {
    const token = getToken();
    if (token) {
      history.push("/vip");
      return;
    }
    setIsLoginModalOpen(true);
    setIsMenuModalOpen(false);
  }, [history, setIsLoginModalOpen, setIsMenuModalOpen]);

  const onGearClick = () => {
    setIsProfileModalOpen(true);
    setIsMenuModalOpen(false);
  };
  if (!isMenuModalOpen) return null;
  return (
    <header className={`${colorMode}-mode mobile-nav-modal z-20`}>
      <div className="mobile-nav-items">
        <Link className="mobile-nav-item" to="/">
          <img className="h-6" src="/img/header/home.svg" alt="" />
        </Link>
        <Link className="mobile-nav-item" to="/about">
          <img className="h-6" src="/img/header/about.svg" alt="" />
        </Link>
        <Link className="mobile-nav-item" to="/history">
          <img className="h-6" src="/img/header/history.svg" alt="" />
        </Link>
        <Link className="mobile-nav-item" to="/discography">
          <img className="h-6" src="/img/header/discography.svg" alt="" />
        </Link>
        <div onClick={onVipClick} className="mobile-nav-item">
          <img className="h-6" src="/img/header/viproom.svg" alt="" />
        </div>
        <Link className="mobile-nav-item" to="/video">
          <img className="h-6" src="/img/header/video.svg" alt="" />
        </Link>
        {isLogin && (
          <img
            className="gear-icon mt-2"
            src="/img/header/gear_icon.svg"
            alt=""
            onClick={onGearClick}
          />
        )}
      </div>
    </header>
  );
};

export default MenuModal;
