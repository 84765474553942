import { useSetRecoilState } from "recoil";
import { isMenuOpenState } from "../../atom";
import { useTheme } from "../../util";
import { Link } from "react-router-dom";
import NavButton from "./NavButton";

const MobileNavbar = ({ shouldSetBgc }) => {
  const { colorMode } = useTheme();
  const setIsMenuOpen = useSetRecoilState(isMenuOpenState);
  const onMenuButtonClick = () => {
    setIsMenuOpen(true);
  };
  return (
    <div
      id="navbar"
      className={`${shouldSetBgc && "background"} ${colorMode}-mode navbar`}
    >
      <NavButton onClick={onMenuButtonClick} />
      <Link to="/" className="text-xl text-white">
        <img
          className="mobile-navbar-logo"
          src={`/img/logo/logo_${colorMode}.png`}
          alt="navbar logo"
        />
      </Link>
      <div />
    </div>
  );
};
export default MobileNavbar;
