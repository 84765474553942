export const onWindowResize = (camera, renderer, composer) => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  camera.aspect = width / height;
  camera.updateProjectionMatrix();

  renderer.setSize(width, height);
  if (composer) composer.setSize(width, height);
};

export const handleMouseMove = (e, mouse) => {
  e.preventDefault();
  mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
};

export const handleTouchEnd = (e, mouse) => {
  //e.preventDefault();
  mouse.x = (e.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(e.changedTouches[0].clientY / window.innerHeight) * 2 + 1;
};
