import { useEffect, useState } from "react";
import { Marquee } from "..";
import { client } from "../../client";
import { SECOND_NEWS_DEFAULT } from "../../constant";

const SecondNews = () => {
  const [secondNews, setSecondNews] = useState(SECOND_NEWS_DEFAULT);
  useEffect(() => {
    const fetchData = async () => {
      const secondNewsRes = await client.getEntry("4AwRQzQWJpXXAcnfcqngGQ");
      setSecondNews(secondNewsRes.fields.secondNews);
    };
    try {
      fetchData();
    } catch {
      setSecondNews(SECOND_NEWS_DEFAULT);
    }
  }, []);
  const { title, desc, linkText, linkUrl, imgUrl } = secondNews;
  return (
    <div className="second-news lg:w-96 w-screen">
      <div className="news-header">
        <Marquee width="2800px">
          <span className="news-title">{title}</span>
        </Marquee>
        <div className="text p-2">
          {desc}
          <a
            className="underline"
            href={linkUrl}
            rel="noreferrer"
            target="_blank"
          >
            {linkText}
          </a>
        </div>
      </div>
      <div
        className="news-image flex justify-center items-center"
        style={{
          background: `url('${imgUrl}') center/cover no-repeat`,
        }}
      >
        <img
          className="bg-white absolute w-72 top-0 right-0"
          src="/img/home/top_tag.svg"
          alt=""
        />
        <img
          src="/img/home/purple_left_tag.svg"
          alt=""
          className="absolute left-0 top-1/2 transform -translate-y-1/2"
        />
        <img
          src="/img/home/white_right_bottom_tag.svg"
          alt=""
          className="absolute right-0 bottom-0"
        />
        <img
          src="/img/home/barcode.svg"
          alt=""
          className="absolute left-0 bottom-0"
        />
      </div>
    </div>
  );
};
export default SecondNews;
