import { useRecoilState } from "recoil";
import { TV, HomeCanvas, HomeNewsView, Entry } from "../components";
import { Helmet } from "react-helmet";
import { enteredState } from "../atom";
import { homeScript } from "../constant/tv";

const HomePage = () => {
  const [entered, setEntered] = useRecoilState(enteredState);
  return (
    <div>
      <Helmet>
        <title>Amazing Show | 美秀集團</title>
      </Helmet>
      <Entry entered={entered} setEntered={setEntered} />
      <HomeCanvas entered={entered} />
      <HomeNewsView />
      {entered && <TV script={homeScript} />}
    </div>
  );
};

export default HomePage;
