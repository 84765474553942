const DesktopMemberBanner = ({ isTablet }) => {
  return (
    <>
      {isTablet ? (
        <img src="/img/about/about_landing_mobile.png" alt="" />
      ) : (
        <img src="/img/about/about_landing_pc.png" alt="" />
      )}
    </>
  );
};
export default DesktopMemberBanner;
