import { Howl } from "howler";
import { soundMap } from "../constant";
import { useLocation } from "react-router";
import { useEffect, useMemo, createContext } from "react";
import { useRecoilValue } from "recoil";
import { enteredState } from "../atom";
import { isMobile } from "react-device-detect";

export class BackgroundSound {
  constructor(soundKey) {
    this.sound = new Howl({ src: soundMap[soundKey], loop: true, html5: true });
  }
  play(volume = 0.3) {
    this.sound.fade(1, 0, 1000);
    this.sound.volume(volume);
    this.sound.play();
  }
  stop() {
    this.sound.stop();
  }
}

export const playSoundByKey = (soundKey, loop = false) => {
  const src = soundMap[soundKey];
  if (!src) return;
  const sound = new Howl({ src, loop, html5: true });
  sound.volume(0.2);
  sound.play();
};

export const AudioContext = createContext(null);
export const AudioContextProvider = ({ children }) => {
  const location = useLocation();
  const explodedWindSound = useMemo(
    () => new BackgroundSound("explodedWind"),
    []
  );
  const backgroundWindSound = useMemo(
    () => new BackgroundSound("homeWind"),
    []
  );
  useEffect(() => {
    if (location.pathname !== "/") {
      backgroundWindSound.stop();
      explodedWindSound.stop();
    }
  }, [backgroundWindSound, explodedWindSound, location.pathname]);

  const entered = useRecoilValue(enteredState);
  useEffect(() => {
    if (isMobile) return;
    if (location.pathname === "/") backgroundWindSound.play("homeWind");
  }, [backgroundWindSound, entered, location.pathname]);

  return (
    <AudioContext.Provider value={{ explodedWindSound }}>
      {children}
    </AudioContext.Provider>
  );
};
