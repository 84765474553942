export const soundMap = {
  entryWind: "/audio/wind/strange_wind.mp3",
  homeWind: "/audio/wind/wind1.mp3",
  explodedWind: "/audio/wind/strong_wind1.mp3",
  deceitfulMind: "/audio/sound/time_reversal.mp3",
  treasureBowl: "/audio/sound/in_dream.mp3",
  threeHeadedDog: "/audio/sound/wind_monster1.mp3",
  pot: "/audio/sound/diving1.mp3",
  escapePod: "/audio/sound/dark_atmosphere.mp3",
  weapon: "/audio/sound/weapon.wav",
  explosion: "/audio/sound/explosion.wav",
  instrument1: "/audio/instrument/01-1.mp3",
  instrument2: "/audio/instrument/02.mp3",
  instrument3: "/audio/instrument/03.mp3",
  instrument4: "/audio/instrument/04.mp3",
  instrument5: "/audio/instrument/05-1.mp3",
  instrument6: "/audio/instrument/06.mp3",
  instrument7: "/audio/instrument/07-1.mp3",
  instrument8: "/audio/instrument/08.mp3",
  instrument9: "/audio/instrument/09.mp3",
  instrument10: "/audio/instrument/10.mp3",
  drag: "/audio/pull/pull_2.wav",
};
