import { useEffect, useRef, useCallback } from "react";
import gsap from "gsap";
import { useRecoilValue } from "recoil";
import { playSoundByKey } from "../util";
import { isHomeModelLoaded } from "../atom";
import { ReactComponent as EntryBG } from "../asset/entry_bg.svg";

const Entry = ({ entered, setEntered }) => {
  const isHomeModelLoadedValue = useRecoilValue(isHomeModelLoaded);
  const tunnelRef = useRef(null);
  const bgRef = useRef(null);
  const pinballRef = useRef(null);
  const entryRef = useRef(null);
  const onClick = useCallback(() => {
    playSoundByKey("entryWind");
    const onComplete = () => setEntered(true);
    const tl = gsap.timeline({ onComplete });
    // animation start
    tl.to(tunnelRef.current, { opacity: 1 });
    tl.to(bgRef.current, { duration: 0.5, opacity: 0 });
    // animation ends until model loaded
    if (isHomeModelLoadedValue) {
      tl.to(tunnelRef.current, { opacity: 0, delay: 0.5 });
      tl.to(entryRef.current, { duration: 0, opacity: 0 });
      // cleanup
      tl.to(tunnelRef.current, { display: "none" });
      tl.to(entryRef.current, { display: "none" });
    }
  }, [setEntered, isHomeModelLoadedValue]);
  const onPinballLoad = useCallback(() => {
    const tl = gsap.timeline();
    tl.to(pinballRef.current, { duration: 1, opacity: 1 });
  }, []);
  useEffect(() => {
    const upHandler = ({ key }) => {
      if (key === "Enter") onClick();
    };
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keyup", upHandler);
    };
  }, [onClick]);
  if (entered) return null;
  return (
    <div
      ref={entryRef}
      className={`fixed block z-30 h-screen w-screen bg-black`}
    >
      <div ref={bgRef} className={`flex justify-center`}>
        <img
          ref={pinballRef}
          src="/img/entry/entry.png"
          alt=""
          onClick={onClick}
          className="pinball fixed object-fit top-1/2 transform -translate-y-1/2 z-20 cursor-pointer"
          onLoad={onPinballLoad}
        />
        <EntryBG className="entry-bg h-screen" />
      </div>
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
        ref={tunnelRef}
        className="tunnel absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-40 max-w-none w-auto h-screen opacity-0 pointer-events-none"
      >
        <source src="/videos/tunnel.mp4" />
        Your browser does not support HTML video.
      </video>
    </div>
  );
};

export default Entry;
