import Modal from "react-modal";
import ScriptCarousel from "./ScriptCarousel";
import { useMemo } from "react";
import { isMobile } from "react-device-detect";
import { desktopScriptModal, mobileScriptModal } from "../../constant";

const ScriptModal = ({ isOpen, onClose }) => {
  const modalStyle = useMemo(() => {
    return isMobile ? mobileScriptModal : desktopScriptModal;
  }, []);
  return (
    <Modal
      className="room-modal"
      onRequestClose={onClose}
      style={modalStyle}
      isOpen={isOpen}
    >
      <div className="h-full flex flex-col justify-center items-center">
        <ScriptCarousel onClose={onClose} />
      </div>
    </Modal>
  );
};
export default ScriptModal;
