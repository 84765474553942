import { Trans } from "react-i18next";

const LoginButton = ({ children, loginType }) => {
  return (
    <div className={`login-button-wrapper ${loginType} mx-auto`}>
      <div className="login-button flex justify-center items-center">
        <img
          className="login-icon"
          src={`/img/icon/social/${loginType}_circle.svg`}
          alt={`${loginType} icon`}
        />
        <p className="login-button-text text-black">
          <Trans i18nKey="continue_with_login" values={{ loginType }} />
        </p>
      </div>
      {children}
    </div>
  );
};

export default LoginButton;
