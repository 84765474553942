import { useCallback, useState } from "react";
import { SpriteAnimator } from "react-sprite-animator";
import Typist from "react-typist";
import { useInterval } from "../util";
import { Delayed } from "../components";

// to control the sprite animation, pass variable `startFrame` or change the `sprite` path to SpriteAnimator.

const TV = ({
  script,
  interval = 3000,
  callback,
  position = "bottom-8 sm:left-4",
}) => {
  const [stage, setStage] = useState(0);
  const { content, choices = [] } = script[stage];
  const pauseInterval = script.length - 1 === stage || choices.length > 0;
  useInterval(
    async () => {
      setStage(stage + 1);
    },
    pauseInterval ? null : interval // pause the interval by passing null
  );

  const onClickChoice = useCallback(
    (targetStage) => {
      if (callback !== undefined) return callback();
      setStage(targetStage); // default: head to target stage
    },
    [callback]
  );
  return (
    <div
      className={`absolute ${position} flex mx-2 left-1/2 transform -translate-x-1/2 sm:transform-none`}
    >
      <div className="absolute -left-10 bottom-14 w-20 mr-1 block sm:hidden">
        <img src="/img/home/tv_bg.svg" alt="" className="absolute" />
        <SpriteAnimator
          sprite="/img/tv/tv.png"
          width={78}
          height={90}
          fps={8}
          frameCount={26}
          scale={2}
          className="absolute left-1/2 transform -translate-x-1/2 top-2"
        />
      </div>
      <div className="relative w-40 mr-1 hidden sm:block">
        <img src="/img/home/tv_bg.svg" alt="" className="absolute w-40" />
        <SpriteAnimator
          sprite="/img/tv/tv.png"
          width={78}
          height={90}
          fps={8}
          frameCount={26}
          className="absolute left-1/2 transform -translate-x-1/2 top-4"
        />
      </div>
      <div
        className="pt-6 pb-4 pl-16 pr-4 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url('/img/home/tv_dialog.svg')`,
          width: "18rem",
          height: "8rem",
        }}
      >
        <Typist key={stage}>{content}</Typist>
        <div className="flex justify-end">
          {choices.length ? (
            <Delayed>
              {choices.map((c) => (
                <div
                  key={c.choice}
                  className="mx-1 cursor-pointer"
                  onClick={() => onClickChoice(c.goto)}
                >
                  （{c.choice}）
                </div>
              ))}
            </Delayed>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TV;
