import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PC from "./history/PC";
import Mobile from "./history/Mobile";

const HistoryPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isReal, setIsReal] = useState(true);
  useEffect(() => {
    const onResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });
  const isPCView = windowWidth > 428;
  return (
    <div className={`${isReal ? "bg-white" : "bg-black"}`}>
      <Helmet>
        <title>Amazing Show | History</title>
      </Helmet>
      {isPCView ? (
        <PC isReal={isReal} setIsReal={setIsReal} windowWidth={windowWidth} />
      ) : (
        <Mobile
          isReal={isReal}
          setIsReal={setIsReal}
          windowWidth={windowWidth}
        />
      )}
    </div>
  );
};

export default HistoryPage;
