export const getRectStyle = (device, isReal, index) => {
  const elem = document.getElementById(
    `${device}-${isReal ? "real" : "fake"}-${index}`
  );
  const rect = elem.getBoundingClientRect();
  return {
    width: rect.width - (rect.width * 1) / 10,
    top: rect.y + window.pageYOffset + rect.height / 10,
    left: rect.x + rect.width / 20,
  };
};
