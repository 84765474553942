import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Delayed } from "../components";

const arr = [1, 2, 3, 4, 5];
const appearsOn = ["/discography", "/about", "/history"];
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const Ad = () => {
  const location = useLocation();
  const [showAds, setShowAds] = useState(false);
  const [adsCount, setAdsCount] = useState(1);
  const styles = useMemo(
    () =>
      arr.map((_, index) => {
        return {
          index,
          top: Math.random() * (window.innerHeight - 100),
          right: Math.random() * (window.innerWidth - 300),
        };
      }),
    // refresh ads positions whenever enter a new route
    [location] // eslint-disable-line
  );
  // show 1 ad on /about & /history, show 5 ads on /discography
  useEffect(() => {
    if (location.pathname === "/discography") setAdsCount(5);
    else setAdsCount(1);
  }, [location]);
  // showing ads every 30 seconds on /history & /about
  useEffect(() => {
    let id;
    if (location.pathname === "/history" || location.pathname === "/about") {
      id = window.setInterval(() => {
        setShowAds(false);
        window.setTimeout(() => setShowAds(true), 0);
      }, 1000 * 30);
    }
    return () => {
      if (id) window.clearInterval(id);
    };
  }, [location]);
  // show ads after user enter these pages -> discography: 1 second, about & video: 5 seconds
  useEffect(() => {
    setShowAds(false);
    const showAfterSecond = location.pathname === "/discography" ? 1000 : 5000;
    window.setTimeout(() => setShowAds(true), showAfterSecond);
  }, [location.pathname, setShowAds]);
  // only show ads on /discography, /about, /history
  if (!appearsOn.some((a) => a === location.pathname)) return null;
  shuffle(styles);
  const slicedStyles = styles.slice(0, adsCount);
  return (
    <div>
      {showAds &&
        slicedStyles.map((s, i) => (
          <Delayed key={i} waitBeforeShow={300 * (i + 1)}>
            <AdModal style={s} />
          </Delayed>
        ))}
    </div>
  );
};

const AdModal = ({ style }) => {
  const [isOpen, setIsOpen] = useState(true);
  if (!isOpen) return null;
  const { index, top, right } = style;
  return (
    <img
      className="fixed bg-black z-40 cursor-pointer"
      src={`/img/home/ad/${index}.png`}
      alt=""
      style={{ top, right }}
      onClick={() => setIsOpen(false)}
    />
  );
};

export default Ad;
