const Player = () => {
  return (
    <audio className="w-full" controls src="/audio/podcast.mp3">
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  );
};

export default Player;
