const DesktopMemberIntro = ({ memberIndex, clicked, isPCView }) => {
  return (
    <div id="member-intro" className="member-intro-sec flex justify-center">
      {clicked && (
        <img
          className={isPCView ? "" : "w-full sm:w-3/5"}
          src={`/img/about/${isPCView ? "pc" : "mobile"}/${
            memberIndex + 1
          }.png`}
          alt=""
        />
      )}
      {!isPCView && (
        <img
          className="about-intro-bg"
          src={`/img/about/member_bg_mobile.png`}
          alt="團員列背景圖"
        />
      )}
    </div>
  );
};
export default DesktopMemberIntro;
