import SecondNews from "./SecondNews";
import DesktopNews from "./DesktopNews";

const NewsContent = () => {
  return (
    <div className="news-content">
      <SecondNews />
      <DesktopNews />
    </div>
  );
};

export default NewsContent;
