export const profileTitles = {
  name: "name",
  ageRange: "age",
  nickName: "nickname",
  email: "email",
  phone: "phone_number",
  address: "address",
};

export const AGE_RANGES = [
  "",
  "20歲以下",
  "21~25",
  "26~30",
  "31~35",
  "36-40",
  "41~45",
  "45~50",
  "51~55",
  "56~60",
  "60~65",
  "66歲以上",
];
