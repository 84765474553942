import { RecoilRoot } from "recoil";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  AboutPage,
  DiscographyPage,
  VideoPage,
  HomePage,
  HistoryPage,
  TermsPage,
  PolicyPage,
  VIPPage,
  InstrumentPage,
} from "./page";
import { ErrorBoundary } from "react-error-boundary";
import {
  AuthContextProvider,
  AudioContextProvider,
  ThemeContextProvider,
} from "./util";
import {
  ErrorView,
  Navbar,
  MenuModal,
  Footer,
  LoginModal,
  RegisterModal,
  ProfileModal,
  Ad,
} from "./components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const routes = [
  { path: "/about", Component: AboutPage },
  { path: "/discography", Component: DiscographyPage },
  { path: "/video", Component: VideoPage },
  { path: "/history", Component: HistoryPage },
  { path: "/terms", Component: TermsPage },
  { path: "/policy", Component: PolicyPage },
  { path: "/vip", Component: VIPPage },
  { path: "/instrument/:id", Component: InstrumentPage },
  { path: "/", Component: HomePage },
];

function App() {
  return (
    <RecoilRoot>
      <div className="App h-full overflow-hidden">
        <Router>
          <AuthContextProvider>
            <AudioContextProvider>
              <ThemeContextProvider>
                <Navbar />
                <Switch>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} path={path}>
                      <ErrorBoundary FallbackComponent={ErrorView}>
                        <Component />
                      </ErrorBoundary>
                    </Route>
                  ))}
                </Switch>
                <MenuModal />
                <LoginModal />
                <RegisterModal />
                <ProfileModal />
                <Footer />
                <Ad />
              </ThemeContextProvider>
            </AudioContextProvider>
          </AuthContextProvider>
        </Router>
        <ToastContainer />
      </div>
    </RecoilRoot>
  );
}

export default App;
