const Marquee = ({ children, width }) => {
  return (
    <div className="marquee" style={{ width }}>
      <span className="marquee-item">{children}</span>
      <span className="marquee-item">{children}</span>
      <span className="marquee-item">{children}</span>
      <span className="marquee-item">{children}</span>
      <span className="marquee-item">{children}</span>
    </div>
  );
};
export default Marquee;
