import { useState, useCallback } from "react";

export const useInput = ({ type, defaultValue = "", disabled = false }) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (e) => setValue(e.target.value);
  const input = (
    <input value={value} onChange={onChange} type={type} disabled={disabled} />
  );
  return [value, input, setValue];
};

export const ControlledInput = ({
  type,
  placeholder = "",
  value = "",
  setValue,
  disabled = false,
}) => {
  const onChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );
  return (
    <input
      className="modal-input"
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export const debounce = (func, delay = 250) => {
  let timer = null;

  return (args) => {
    let context = this;

    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};
