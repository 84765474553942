import { membersInfos } from "../../constant";

const DesktopMemberRow = ({ setMemberIndex, isPCView }) => {
  const imgClass = `about-member`;
  return (
    <div
      className="about-members"
      style={{ padding: isPCView ? "100px 20px" : "20px 10px" }}
    >
      {membersInfos.map((info, index) => (
        <img
          key={info.key}
          className={imgClass}
          style={{ width: isPCView ? "250px" : "100px" }}
          src={`/img/about/button_${info.key}.png`}
          alt={`${info.name}按鈕的照片`}
          onClick={() => setMemberIndex(index)}
        />
      ))}
      {isPCView && (
        <img
          className="about-members-bg"
          src="/img/about/member_bg.png"
          alt="團員列背景圖"
        />
      )}
    </div>
  );
};

export default DesktopMemberRow;
