import { useCallback, useRef, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { isProfileModalOpenState } from "../atom";
import { useHistory } from "react-router-dom";
import {
  Scene,
  Color,
  Raycaster,
  Vector2,
  Vector3,
  FlatShading,
  PerspectiveCamera,
  AmbientLight,
  HemisphereLight,
  FontLoader,
  TextGeometry,
  MeshNormalMaterial,
  Mesh,
  WebGLRenderer,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import PlayerModal from "./room/PlayerModal";
import ScriptModal from "./room/ScriptModal";
import styles from "./canvas.module.css";
import { onWindowResize } from "../util";
import { client } from "../client";
import { VIP_MARQUEE_DEFAULT } from "../constant";

const dialogMap = {
  Musical_instrument01: "這是一支路邊撿來的炫炮",
  Musical_instrument02: "這支炫炮非常燙",
  Musical_instrument03: "這支炫炮真的很快",
};

// font download from https://www.wfonts.com/font/microsoft-yahei
// convert ttf to json http://gero3.github.io/facetype.js/

const Room = (props) => {
  const canvasRef = useRef(null);
  const history = useHistory();
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [isScriptModalOpen, setIsScriptModalOpen] = useState(false);
  const vipTvLink = useRef(VIP_MARQUEE_DEFAULT.tvLink);
  const vipMarquee1 = useRef(VIP_MARQUEE_DEFAULT.marquee1);
  const vipMarquee2 = useRef(VIP_MARQUEE_DEFAULT.marquee2);
  const [dialog, setDialog] = useState(null);
  const setIsProfileModalOpen = useSetRecoilState(isProfileModalOpenState);

  const goToInstrument = (dialog) => {
    const id = parseInt(dialog.charAt(dialog.length - 1));
    history.push(`/instrument/${id}`);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await client.getEntry("4WBI5zu9x7W0JpVP5BZVWY");
        vipTvLink.current = res.fields.vip.tvLink;
        vipMarquee1.current = res.fields.vip.marquee1;
        vipMarquee2.current = res.fields.vip.marquee2;
      } catch (err) {
        vipTvLink.current = VIP_MARQUEE_DEFAULT.tvLink;
        vipMarquee1.current = VIP_MARQUEE_DEFAULT.marquee1;
        vipMarquee2.current = VIP_MARQUEE_DEFAULT.marquee2;
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    let camera;
    let scene;
    let model;
    let raycaster;
    let mouse;
    let renderer;
    let controls;
    let animationFrameId;
    let nameMesh;
    let targetName = null; // storing the object name which is being clicked
    let intersectObjects = [];

    init();

    function init() {
      // scene
      scene = new Scene();
      scene.background = new Color("black");

      // interaction
      raycaster = new Raycaster();
      mouse = new Vector2(1, 1);

      createCamera();
      createLights();
      loadObjects();
      createControls();
      createRenderer();
      render();
    }

    function createCamera() {
      const fov = 70;
      const aspect = window.innerWidth / window.innerHeight;
      const near = 0.1;
      const far = 1000;
      camera = new PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 0, 20);
    }

    function createLights() {
      const ambientLight = new AmbientLight(0x593f3f); // soft white light
      scene.add(ambientLight);

      const hemiLight = new HemisphereLight(0xffffff, 0x616161);
      hemiLight.position.set(0, 0, 0);
      scene.add(hemiLight);
    }

    async function loadObjects() {
      const loader = new GLTFLoader();

      function loadRoomBox() {
        return new Promise((resolve, _reject) => {
          loader.load("/models/room/room.gltf", (gltf) => {
            gltf.scene.traverse((child) => {
              if (child instanceof Mesh) {
                child.material.metalness = 0;
                if (child.parent.name === "Button_range_") {
                  child.visible = false;
                  intersectObjects.push(child);
                }
              }
            });

            model = gltf.scene;
            model.position.copy(new Vector3(0, -2.5, -10));
            model.scale.set(10, 10, 10);
            scene.add(model);
            resolve();
          });
        });
      }

      async function loadFont() {
        const loader = new FontLoader();
        loader.load("/MicrosoftYaHei_Regular.json", function (font) {
          const nameGeometry = new TextGeometry(vipMarquee1.current, {
            font,
            size: 1,
            height: 1,
          });
          const nameMaterial = new MeshNormalMaterial({
            flatShading: FlatShading,
            transparent: true,
            opacity: 0.9,
          });
          nameMesh = new Mesh(nameGeometry, nameMaterial);
          nameMesh.position.x = -35;
          nameMesh.position.y = 100;
          nameMesh.position.z = -133;
          nameMesh.rotation.x = 0.2;
          nameMesh.scale.x = 4;
          nameMesh.scale.y = 3.1;

          // setting the name so it can be easily controlled
          nameMesh.name = "nameMesh";
          const messageGeometry = new TextGeometry(vipMarquee2.current, {
            font,
            size: 1,
            height: 1,
          });
          const messageMaterial = new MeshNormalMaterial({
            flatShading: FlatShading,
            transparent: true,
            opacity: 0.9,
          });
          const messageMesh = new Mesh(messageGeometry, messageMaterial);
          messageMesh.position.x = -36;
          messageMesh.position.y = 86;
          messageMesh.position.z = -135;
          messageMesh.scale.x = 4.3;
          messageMesh.scale.y = 3.8;
          scene.add(nameMesh);
          scene.add(messageMesh);
        });
      }

      async function loadAll() {
        // make sure object are added to scene in order
        await loadRoomBox();
        await loadFont();
      }

      loadAll();
    }

    function createRenderer() {
      renderer = new WebGLRenderer({ antialias: true });
      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.gammaFactor = 2.2;
      renderer.gammaOutput = true;

      canvas.appendChild(renderer.domElement);
    }

    function createControls() {
      controls = new OrbitControls(camera, canvas);
      controls.rotateSpeed = -0.5; // same direction when user drags
      controls.enableZoom = false;
    }

    function render() {
      setTimeout(function () {
        animationFrameId = window.requestAnimationFrame(render);
      }, 1000 / 20); // 20 fps
      renderer.render(scene, camera);
    }

    function onClick(e) {
      switch (targetName) {
        case "TV1":
          return window.open(vipTvLink.current, "_blank");
        case "Musical_instrument01":
        case "Musical_instrument02":
        case "Musical_instrument03":
          return setDialog(targetName);
        case "photo01":
          return setIsScriptModalOpen(true);
        case "Controller":
          return setIsPlayerModalOpen(true);
        default:
          return setDialog(null);
      }
    }

    function onMouseMove(event) {
      event.preventDefault();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersection = raycaster.intersectObjects(intersectObjects);
      if (intersection.length > 0) {
        targetName = intersection[0].object.name;
        document.body.style.cursor = "pointer";
      } else {
        targetName = null;
        document.body.style.cursor = "auto";
      }
    }

    function onDocumentTouchEnd(event) {
      mouse.x = (event.changedTouches[0].clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.changedTouches[0].clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersection = raycaster.intersectObjects(intersectObjects);
      if (intersection.length > 0) {
        targetName = intersection[0].object.name;
      } else {
        targetName = null;
      }
    }

    window.addEventListener(
      "resize",
      () => onWindowResize(camera, renderer),
      false
    );
    canvas.addEventListener("pointerdown", onClick);
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchend", onDocumentTouchEnd);
    return () => {
      window.removeEventListener("resize", () =>
        onWindowResize(camera, renderer)
      );
      window.cancelAnimationFrame(animationFrameId);
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchend", onDocumentTouchEnd);
      canvas.removeEventListener("pointerdown", onClick);
    };
  }, [history, setIsProfileModalOpen, vipTvLink, vipMarquee1, vipMarquee2]);

  const closePlayerModal = useCallback(() => {
    setIsPlayerModalOpen(false);
  }, []);

  const closeScriptModal = useCallback(() => {
    setIsScriptModalOpen(false);
  }, []);

  return (
    <>
      <div className={styles.canvas} ref={canvasRef} {...props} />
      {dialog && (
        <div
          className="fixed bottom-4 left-1/2 transform -translate-x-1/2 py-6 px-8 text-white"
          style={{
            width: "326px",
            height: "99px",
            backgroundImage: `url('/img/viproom/dialog.svg')`,
          }}
        >
          {dialogMap[dialog]}
          <div
            onClick={() => goToInstrument(dialog)}
            className="absolute bottom-5 right-6 cursor-pointer underline"
          >
            我要去
          </div>
        </div>
      )}
      <PlayerModal isOpen={isPlayerModalOpen} onClose={closePlayerModal} />
      <ScriptModal isOpen={isScriptModalOpen} onClose={closeScriptModal} />
    </>
  );
};

export default Room;
