import { LoopOnce } from "three";

export const instrumentsInfo1 = {
  "01": {
    sound: "instrument1",
    clips: {
      click: {
        name: "clip1",
        start: 0,
        end: 15,
        loopMode: LoopOnce,
        clampWhenFinished: true,
      },
      release: {
        name: "clip1_2",
        start: 15,
        end: 25,
        loopMode: LoopOnce,
        clampWhenFinished: true,
      },
    },
  },
  "02": {
    sound: "instrument2",
    clips: {
      click: {
        name: "clip2",
        start: 25,
        end: 70,
        loopMode: undefined,
        clampWhenFinished: false,
      },
      release: undefined,
    },
  },
  "03": {
    sound: "instrument3",
    clips: {
      click: {
        name: "clip3",
        start: 70,
        end: 85,
        loopMode: LoopOnce,
        clampWhenFinished: true,
      },
      release: {
        name: "clip3_2",
        start: 90,
        end: 100,
        loopMode: LoopOnce,
        clampWhenFinished: true,
      },
    },
  },
  "04": {
    sound: "instrument4",
  },
};

export const instrumentsInfo2 = {
  "05": {
    sound: "instrument5",
  },
  "06": {
    sound: "instrument6",
  },
  "07": {
    sound: "instrument7",
  },
  "08": {
    sound: "instrument8",
  },
  "09": {
    sound: "instrument9",
  },
};

export const instrumentsInfo3 = {
  Button_range: {
    sound: "instrument10",
  },
};
