export const albums = [
  {
    imgUrl: "sound_check_2017",
    kkboxUrl: "https://rock-mobile.lnk.to/SoundCheck",
    spotifyUrl: "https://rock-mobile.lnk.to/SoundCheck",
    merchs: [
      "/img/discography/0/0.jpg",
      "/img/discography/0/1.jpg",
      "/img/discography/0/2.jpg",
      "/img/discography/0/3.jpg",
      "/img/discography/0/4.jpg",
      "/img/discography/0/5.jpg",
      "/img/discography/0/6.jpg",
      "/img/discography/0/7.jpg",
      "/img/discography/0/8.jpg",
      "/img/discography/0/9.jpg",
    ],
  },
  {
    imgUrl: "dian_huo",
    kkboxUrl: "https://rock-mobile.lnk.to/KingofLight",
    spotifyUrl: "https://www.fireonshop.com/categories/bisiugroup",
    merchs: [
      "/img/discography/1/0.png",
      "/img/discography/1/1.jpg",
      "/img/discography/1/2.jpg",
      "/img/discography/1/3.jpg",
      "/img/discography/1/4.jpg",
      "/img/discography/1/5.jpg",
      "/img/discography/1/6.jpg",
      "/img/discography/1/7.jpg",
    ],
  },
  {
    imgUrl: "sound_check_2019",
    kkboxUrl: "https://www.fireonshop.com/categories/bisiugroup",
    spotifyUrl: "https://www.fireonshop.com/categories/bisiugroup",
    merchs: [
      "/img/discography/2/0.png",
      "/img/discography/2/1.png",
      "/img/discography/2/2.png",
      "/img/discography/2/3.png",
      "/img/discography/2/4.png",
      "/img/discography/2/5.png",
    ],
  },
  {
    imgUrl: "duo_se",
    kkboxUrl: "https://rock-mobile.lnk.to/Paramount",
    spotifyUrl: "https://www.fireonshop.com/categories/bisiugroup",
    merchs: [
      "/img/home/news_banner1.png",
      "/img/home/news_banner2.png",
      "/img/home/news_banner3.png",
      "/img/home/news_banner4.png",
      "/img/home/news_banner5.png",
      "/img/home/news_banner6.png",
    ],
  },
];
