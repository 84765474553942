import { useEffect, useRef } from "react";
import {
  Clock,
  Vector2,
  Scene,
  PerspectiveCamera,
  AmbientLight,
  HemisphereLight,
  WebGLRenderer,
  sRGBEncoding,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import styles from "./canvas.module.css";
import { onWindowResize, handleMouseMove, handleTouchEnd } from "../util";
import { Instrument1, Instrument2, Instrument3 } from "../components";

const InstrumentCanvas = ({ id }) => {
  const canvasRef = useRef(null);
  useEffect(() => {
    // js section
    const canvas = canvasRef.current;
    let camera;
    let scene;
    let renderer;
    let mouse = new Vector2();
    let controls;
    let clock;
    let animationFrameId;
    let model;
    init();
    function init() {
      clock = new Clock();
      scene = new Scene();
      // show black background before bgTexture is loaded
      createCamera();
      createLights();
      loadModel();
      createRenderer();
      createControls();
      render();
    }
    function createCamera() {
      const aspect = window.innerWidth / window.innerHeight;
      camera = new PerspectiveCamera(40, aspect, 1, 200);
      camera.position.set(0, 0, 20);
    }

    function createLights() {
      const ambientLight = new AmbientLight(0x593f3f); // soft white light
      scene.add(ambientLight);

      const hemiLight = new HemisphereLight(0xffffff, 0x616161);
      hemiLight.position.set(0, 0, 0);
      scene.add(hemiLight);
    }

    function createControls() {
      controls = new OrbitControls(camera, canvas);
      controls.minDistance = 3;
      controls.maxDistance = 20;
    }

    function loadModel() {
      if (id === "1") model = new Instrument1();
      else if (id === "2") model = new Instrument2();
      else if (id === "3") model = new Instrument3();
      if (model) model.load(scene);
    }

    function createRenderer() {
      renderer = new WebGLRenderer({ antialias: true, alpha: true }); // alpha for transparent bg
      renderer.setSize(canvas.clientWidth, canvas.clientHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.outputEncoding = sRGBEncoding;

      canvas.appendChild(renderer.domElement);
    }

    const onMouseMove = (e) => {
      model.hover();
      handleMouseMove(e, mouse);
    };

    const onTouchEnd = (e) => {
      handleTouchEnd(e, mouse);
    };

    const onPointerDown = () => {
      model.click();
    };

    const onPointerUp = () => {
      model.stop();
    };

    function render() {
      model.raycaster.setFromCamera(mouse, camera);

      const delta = clock.getDelta();
      const animationMixer = model.animationMixer;
      if (animationMixer) animationMixer.update(delta);

      setTimeout(function () {
        animationFrameId = window.requestAnimationFrame(render);
      }, 1000 / 30); // 30 fps
      renderer.render(scene, camera);
    }
    canvas.addEventListener("pointerdown", onPointerDown);
    canvas.addEventListener("pointerup", onPointerUp);
    window.addEventListener("resize", () => onWindowResize(camera, renderer));
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("touchend", onTouchEnd);
    return () => {
      canvas.removeEventListener("pointerdown", onPointerDown);
      canvas.removeEventListener("pointerup", onPointerUp);
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchend", onTouchEnd);
      window.removeEventListener("resize", () =>
        onWindowResize(camera, renderer)
      );
    };
  }, [id]);
  return (
    <div
      className="w-screen h-screen"
      style={{
        background: `url("/img/instrument/bg${id}.jpg") center/cover no-repeat`,
      }}
    >
      <img
        className="instrument-deco"
        src="/img/instrument/deco_left.svg"
        alt=""
      />
      <img
        className="instrument-deco"
        src="/img/instrument/deco_center.png"
        alt=""
      />
      <img
        className="instrument-deco"
        src="/img/instrument/deco_right.svg"
        alt=""
      />
      <div className={styles.canvas} ref={canvasRef} />
    </div>
  );
};

export default InstrumentCanvas;
