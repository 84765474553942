import { useCallback } from "react";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { isProfileModalOpenState } from "../../atom";
import { useAuthContext, toastDark } from "../../util";
import { profileTitles, AGE_RANGES } from "../../constant";

const ProfileView = ({ profile }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { logout } = useAuthContext();
  const setIsOpen = useSetRecoilState(isProfileModalOpenState);
  const onLogout = useCallback(() => {
    const callback = () => {
      setIsOpen(false);
      toastDark('登出成功🙌🏻"');
      history.push("/");
    };
    logout(callback);
  }, [history, logout, setIsOpen]);

  const infos = [
    {
      title: profileTitles.nickName,
      value: profile.nickName,
    },
    {
      title: profileTitles.ageRange,
      value: AGE_RANGES[profile.ageRange],
    },
    {
      title: profileTitles.email,
      value: profile.email,
    },
    {
      title: profileTitles.name,
      value: profile.name,
    },
    {
      title: profileTitles.phone,
      value: profile.phone,
    },
    {
      title: profileTitles.address,
      value: profile.address,
    },
  ];
  return (
    <>
      {infos.map((info) => (
        <div key={info.title} className="info-row">
          <h4>{t(info.title)}</h4>
          <p>{info.value}</p>
        </div>
      ))}
      <button
        className="bg-transparent text-red-500 mx-auto w-full"
        onClick={onLogout}
      >
        <span className="font-en">Logout</span>
      </button>
    </>
  );
};
export default ProfileView;
