import { useCallback, useState, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";
import { isRegisterModalOpenState } from "../atom";
import { useSetRecoilState } from "recoil";
import { toastDark } from "./toast";
import { apiCall } from "../util";

export const getToken = () => localStorage.getItem("at") || "";
export const setToken = (token) => {
  localStorage.setItem("at", token);
};

export const getAccessToken = () => localStorage.getItem("accessToken") || "";
export const setAccessToken = (accessToken) => {
  localStorage.setItem("accessToken", accessToken);
};

export const getUserName = () => localStorage.getItem("user") || "";
export const setUserName = (user) => {
  localStorage.setItem("user", user);
};

export const resetLoginInfo = () => {
  setUserName("");
  setToken("");
  setAccessToken("");
};

const useAuth = () => {
  const history = useHistory();
  const [loginType, setLoginType] = useState("");
  const setIsRegisterModalOpen = useSetRecoilState(isRegisterModalOpenState);

  const login = useCallback(
    (type, accessToken, name, callback) => {
      const onSuccess = (res) => {
        const { register, userData } = res;
        callback(); // setIsLoginModalOpen(false)
        if (register) {
          const { token, ...user } = userData;
          setToken(token);
          setUserName(user.nickName);
          toastDark("登入成功 🙌🏻");
          history.push("/vip");
        } else {
          type && setLoginType(type);
          setAccessToken(accessToken);
          setIsRegisterModalOpen(true);
        }
      };
      const onError = () => {
        toastDark("登入失敗 😠");
      };
      const data = { accessToken, name };
      apiCall({
        url: `v2/login/${type}`,
        method: "post",
        data,
        onSuccess,
        onError,
      });
    },
    [history, setIsRegisterModalOpen]
  );

  const logout = useCallback((callback) => {
    callback && callback();
    resetLoginInfo();
  }, []);

  const register = useCallback((type, userData, callback) => {
    const onSuccess = (res) => {
      const { token, ...user } = res.userData;
      setToken(token);
      setUserName(user.nickName);
      toastDark("註冊成功🙌🏻");
      callback && callback(); // history.push('/vip')
    };
    const onError = () => {
      toastDark("註冊失敗😠");
    };
    const data = { accessToken: getAccessToken(), ...userData };
    apiCall({
      url: `v2/register/${type}`,
      method: "post",
      data,
      onSuccess,
      onError,
    });
  }, []);

  const checkAuth = useCallback(() => {
    const onSuccess = () => {};
    const onError = () => {
      toastDark("請登入 😠");
      history.push("/");
      resetLoginInfo();
    };
    apiCall({
      url: `v2/check`,
      method: "get",
      onSuccess,
      onError,
    });
  }, [history]);

  return { loginType, login, logout, register, checkAuth };
};

const AuthContext = createContext(null);
export const AuthContextProvider = ({ children }) => {
  const value = useAuth();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuthContext = () => {
  return useContext(AuthContext);
};
