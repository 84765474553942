import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "..";
import {
  ControlledInput as Input,
  testPhoneRegExp,
  testEmailRegExp,
  debounce,
  testNameRegExp,
  containsComma,
} from "../../util";
import { profileTitles, AGE_RANGES } from "../../constant";

const ProfileForm = ({ submit, submitText, profile }) => {
  const { t } = useTranslation();
  const [hint, setHint] = useState("");
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const [ageRange, setAgeRange] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (profile) {
      setName(profile.name);
      setNickName(profile.nickName);
      setAgeRange(profile.ageRange);
      setEmail(profile.email);
      setPhone(profile.phone);
      setAddress(profile.address);
    }
  }, [profile]);

  const inputFields = [
    {
      title: profileTitles.nickName,
      value: nickName,
      setValue: setNickName,
      type: "text",
      required: true,
    },
    {
      title: profileTitles.ageRange,
      value: ageRange,
      setValue: setAgeRange,
      type: "radio",
      options: AGE_RANGES,
      required: true,
    },
    {
      title: profileTitles.email,
      value: email,
      setValue: setEmail,
      type: "text",
      required: true,
    },
    {
      title: profileTitles.name,
      value: name,
      setValue: setName,
      type: "text",
      required: false,
    },
    {
      title: profileTitles.phone,
      value: phone,
      setValue: setPhone,
      type: "text",
      required: false,
    },
    {
      title: profileTitles.address,
      value: address,
      setValue: setAddress,
      type: "text",
      required: false,
    },
  ];

  const getUnfinishedFields = () =>
    inputFields
      .filter((field) => field.required)
      .reduce((accumulator, field) => {
        if (!field.value) accumulator.push(t(field.title));
        return accumulator;
      }, []);

  const validateFields = () => {
    let errors = [];

    const unfinishedFields = getUnfinishedFields();
    if (unfinishedFields.length) {
      errors.push(`${unfinishedFields.join("、")} 為必填欄位`);
    }

    if (!testEmailRegExp(email)) {
      errors.push("請確認 email 格式");
    }

    if (phone && !testPhoneRegExp(phone)) {
      errors.push("請確認電話號碼格式");
    }

    if (name && !testNameRegExp(name)) {
      errors.push("名字不可超過64字元");
    }

    for (const field of inputFields) {
      if (containsComma(field.value)) {
        errors.push(`${t(field.title)}欄位不可包含半形逗號`);
      }
    }

    return errors;
  };

  const onSubmit = debounce(() => {
    const errors = validateFields();

    if (errors.length) {
      setHint(errors.join("、"));
      return;
    }

    setHint("");

    const data = {
      name,
      ageRange: parseInt(ageRange),
      phone,
      address,
      email: email.trim(),
      nickName,
    };

    submit(data);
  });
  return (
    <>
      <div className="profile-form flex flex-col">
        {inputFields.map((field) => {
          return (
            <div key={field.title}>
              <div className="modal-input-title">
                <span className="font-en">{t(field.title)}</span>
                {field.required && <span className="text-red-500">*</span>}
              </div>
              <div className="input-container mx-1">
                {field.type === "text" ? (
                  <Input
                    value={field.value}
                    setValue={field.setValue}
                    type="text"
                  />
                ) : (
                  <Select
                    value={field.value}
                    setValue={field.setValue}
                    options={field.options}
                  />
                )}
              </div>
            </div>
          );
        })}
        {hint && (
          <div className="text-sm text-center text-red-500 mt-5">{hint}</div>
        )}
        <br />
        <button
          className="bg-transparent rounded text-white p-2"
          onClick={onSubmit}
        >
          <span className="font-en">{submitText}</span>
        </button>
      </div>
    </>
  );
};

export default ProfileForm;
