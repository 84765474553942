import FacebookLogin from "react-facebook-login";
import { FB_APP_ID } from "../../constant";
import LoginButton from "./LoginButton";
const FacebookLoginButton = ({ login }) => {
  const responseFacebook = (response) => {
    const { accessToken, name } = response;
    login("facebook", accessToken, name);
  };
  return (
    <LoginButton loginType="facebook">
      <FacebookLogin
        appId={FB_APP_ID}
        autoLoad={false}
        fields="name"
        scope="public_profile"
        textButton=""
        callback={responseFacebook}
        disableMobileRedirect={true}
      />
    </LoginButton>
  );
};

export default FacebookLoginButton;
