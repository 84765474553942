export const PCAlbumCard = ({ album, onClick }) => {
  const { imgUrl, spotifyUrl, kkboxUrl } = album;
  return (
    <div className="relative w-72">
      <img
        className="cursor-pointer"
        src={`/img/discography/${imgUrl}.png`}
        alt=""
        onClick={onClick}
      />
      <a href={kkboxUrl} rel="noopener noreferrer" target="_blank">
        <div className="absolute bottom-16 right-1/2 w-16 h-5" />
      </a>
      <a href={spotifyUrl} rel="noopener noreferrer" target="_blank">
        <div className="absolute bottom-16 left-1/2 w-16 h-5" />
      </a>
    </div>
  );
};

export const MobileAlbumCard = ({ album }) => {
  const { imgUrl, spotifyUrl, kkboxUrl } = album;
  return (
    <div className="album-card relative w-full sm:w-1/2">
      <img className="" src={`/img/discography/${imgUrl}.png`} alt="" />
      <a href={kkboxUrl} rel="noopener noreferrer" target="_blank">
        <div className="absolute right-1/2 w-16 h-10" />
      </a>
      <a href={spotifyUrl} rel="noopener noreferrer" target="_blank">
        <div className="absolute left-1/2 w-16 h-10 cursor-pointer" />
      </a>
    </div>
  );
};
