import NewsBanner from "./home-news/NewsBanner";
import NewsContent from "./home-news/NewsContent";

const HomeNewsView = () => {
  return (
    <div className="home-news">
      <NewsBanner />
      <NewsContent />
    </div>
  );
};
export default HomeNewsView;
