export const homeScript = [
  // --------------------- Step 1 ------------------------------
  {
    content: "「......」", // 0
  },
  {
    content: "電視君：嗨您好阿，這裡什麼都沒有呢...", // 1
    choices: [
      { choice: "繼續", goto: 2 },
      { choice: "只能繼續", goto: 2 },
    ],
  },
  // --------------------- Step 2 ------------------------------
  { content: "「...你好」" }, // 2
  { content: "電視君：你這麼想跟我說話嗎?偶爾會有這樣的人過來..." }, // 3
  {
    content: "只有沒搞頭的人才會獨自跟機器說話，呵呵", // 4
    choices: [
      { choice: "繼續談話", goto: 5 },
      { choice: "閉嘴離開", goto: 8 },
    ],
  },
  // --------------------- Step 3 ------------------------------
  { content: "「請問...這裡是哪裡？」" }, // 5
  { content: "電視君：不用想要從我身上打聽到什麼喔，我只是一台電視！電視！" }, // 6
  {
    content: "我甚至沒有手、沒有遙控器！不能轉台ㄟ，好笑吧?", // 7
    choices: [
      { choice: "輕輕拍著電視君", goto: 10 },
      { choice: "用力敲打電視君", goto: 13 },
    ],
  },
  // --------------------- Step 4 ------------------------------
  { content: "「那我就不打擾了」" }, // 8
  {
    content: "電視君：喂～別這樣走掉，唉唷我開玩笑的啦~", // 9
    choices: [
      {
        choice: "語帶威脅回應",
        goto: 16,
      },
      {
        choice: "說自己也開玩笑",
        goto: 20,
      },
    ],
  },
  // --------------------- Step 5 ------------------------------
  { content: "「不然我可以幫你什麼？」" }, // 10
  { content: "電視君：上面這台飛船，跟我對看幾百年了，很礙眼。" }, // 11
  {
    content: "不然幫我確認一下有無生還者？也許誰拿走我的遙控器...", // 12
    choices: [{ choice: "詢問該如何登船", goto: 22 }],
  },
  // --------------------- Step 6 ------------------------------
  { content: "「這台機器是不是壞了阿?」" }, // 13
  {
    content: "電視君：不要，不要，很癢啦，哈哈就是那裡就是那裡", // 14
  },
  {
    content: "喂～～你大老遠跑來這裡就是想攻擊我的村莊？", // 15
    choices: [
      { choice: "語帶威脅回應", goto: 16 },
      { choice: "持續破壞電視君", goto: 25 },
    ],
  },
  // --------------------- Step 7 ------------------------------
  { content: "「說！把你知道的都說出來！」" }, // 16
  { content: "電視君：好啦好啦，上方的飛船自體旋轉很久了似乎是壞了" }, // 17
  { content: "你戳戳看，看他會不會動起來！" }, // 18
  {
    content: "好奇心帶來破壞，破壞帶來出路，真好奇在你手中他會變成什麼模樣", // 19
    choices: [
      { choice: "逼問其他秘密", goto: 28 },
      { choice: "決定自行探索", goto: 32 },
    ],
  },
  // --------------------- Step 8 ------------------------------
  {
    content: "「我也開玩笑的啦，不會走的啦，不現在弄個明白就太遜了」", // 20
  },
  {
    content: "電視君：這麼說你很勇喔？", // 21
    choices: [{ choice: "我超勇的啦", goto: 34 }],
  },
  // --------------------- Step 9 ------------------------------
  { content: "「怎麼尋找其他生還者?」" }, // 22
  { content: "電視君：移動你的手指，在飛船位置探索，試著拖曳看看能否將它爆破" }, // 23
  {
    content: "從碎片中可能找到通往其他區域的路線。可能找到什麼好康的喔。", // 24
    choices: [
      { choice: "還有什麼探索的建議", goto: 28 },
      { choice: "好康?是新遊戲嗎?", goto: 38 },
    ],
  },
  // --------------------- Step 10 ------------------------------
  { content: "(敲敲打打但你的手卻不感到疼痛)" }, // 25
  { content: "(一陣天旋地轉)" }, // 26
  {
    content: "(望著頭頂上的飛船眼皮逐漸沉重...)", // 27
    choices: [{ choice: "將自己喚醒", goto: 0 }],
  },
  // --------------------- Step 11 ------------------------------
  { content: "「還有什麼探索建議嗎?」" }, // 28
  { content: "電視君：優先找到逃生倉吧，也就是飛船中的VIP房間" }, // 29
  {
    content: "只要順利登入，也許就可以明白這一切了", // 30
    choices: [{ choice: "啊，是全息演唱會的入口", goto: 0 }],
  }, // 30
  // --------------------- Step 12 ------------------------------
  { content: "「謝謝我決定四處看看」" }, // 31
  { content: "電視君：我看你是完全是不懂喔" }, // 32
  {
    content:
      "還是提醒你，不要忘了那個稱作VIP房的地方，也許那裏有你所需的一切...好康...", // 33
    choices: [
      { choice: "好康?是新遊戲嗎", goto: 38 },
      { choice: "馬上到VIP房報到", goto: 0 },
    ],
  },
  // --------------------- Step 13 ------------------------------
  { content: "「開玩笑我超勇的啦!」" }, // 34
  { content: "電視君：很勇嘛身材不錯喔蠻結實的嘛" }, // 35
  { content: "看來你是萬中選一的那位，試著拖拉飛船看看" }, // 36
  {
    content: "說不准你能解除我千年來的詛咒...找到傳說中的房間", // 37
    choices: [{ choice: "請給我更詳細的資訊", goto: 28 }],
  },
  // --------------------- Step 14 ------------------------------
  { content: "「什麼好康的?是新遊戲嗎?」" }, // 38
  { content: "電視君：比遊戲還刺激喔，我用說的說不清楚" }, // 39
  { content: "但如果你成功登入那個區域，房間內請360度盡情搜索" }, // 40
  {
    content:
      "請記得，長得跟我一模一樣的傢伙也在，對它殘暴一點吧，沒準你能逃出這裏呢，呵呵", // 41
    choices: [{ choice: "馬上到VIP房報到", goto: 0 }],
  },
];

export const instrumentScript = [
  {
    content: "上面按鈕你長按看看～",
  },
  {
    content: "放大看看嘛！",
  },
  {
    content: "真的不碰碰看嗎？",
  },
  {
    content: "不想玩了？",
    choices: [{ choice: "離開" }],
  },
];
