import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Controller from "../../components/video/Controller";
import { ReactComponent as VideoBGMobile } from "../../asset/video_bg_mobile.svg";

const Mobile = ({ videos, windowWidth, videoIndex, setVideoIndex }) => {
  const [playing, setPlaying] = useState(false);
  const [playerStyle, setPlayerStyle] = useState({});
  useEffect(() => {
    // remember to change the id in svg file
    const playVideo = () => setPlaying(!playing);
    const playButton = document.getElementById("mobile_play_button");
    playButton.style.cursor = "pointer";
    playButton.addEventListener("click", playVideo);

    const playNextVideo = () => setVideoIndex((videoIndex + 1) % videos.length);
    const nextVideoButton = document.getElementById("mobile_next_video_button");
    nextVideoButton.style.cursor = "pointer";
    nextVideoButton.addEventListener("click", playNextVideo);

    const playPrevVideo = () =>
      setVideoIndex(videoIndex - 1 >= 0 ? videoIndex - 1 : videos.length - 1);
    const prevVideoButton = document.getElementById("mobile_prev_video_button");
    prevVideoButton.style.cursor = "pointer";
    prevVideoButton.addEventListener("click", playPrevVideo);

    return () => {
      playButton.removeEventListener("click", playVideo);
      nextVideoButton.removeEventListener("click", playNextVideo);
      prevVideoButton.removeEventListener("click", playPrevVideo);
    };
  }, [windowWidth, playing, videoIndex, setVideoIndex, videos]);
  useEffect(() => {
    const videoScreen = document.getElementById("mobile_screen");
    const rect = videoScreen.getBoundingClientRect();
    const { width, height, x, y } = rect;
    setPlayerStyle({ width, height, top: y, left: x });
  }, [windowWidth]);
  const controllerCount = 4;
  let _videos =
    videoIndex + controllerCount > videos.length - 1
      ? [
          ...videos.slice(videoIndex + 1),
          ...videos.slice(
            0,
            controllerCount - (videos.length - 1 - videoIndex)
          ),
        ]
      : videos.slice(videoIndex + 1, videoIndex + 1 + controllerCount);
  _videos = _videos.slice(0, 4);
  return (
    <div>
      <div className="control-room relative">
        <ReactPlayer
          id="control-player-mobile"
          className="control-player-mobile absolute"
          width={playerStyle.width}
          height={playerStyle.height}
          style={{
            top: playerStyle.top,
            left: playerStyle.left,
          }}
          playing={playing}
          controls={false}
          url={videos[videoIndex].url}
        />
      </div>
      <VideoBGMobile className="w-full" />
      <div className="flex flex-wrap justify-center">
        {_videos.map((v) => (
          <Controller key={v.index} {...v} setVideoIndex={setVideoIndex} />
        ))}
      </div>
    </div>
  );
};

export default Mobile;
