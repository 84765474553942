import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { getToken, useTheme } from "../../util";
import { useSetRecoilState } from "recoil";
import { isLoginModalOpenState, isProfileModalOpenState } from "../../atom";

const Navbar = ({ shouldSetBgc }) => {
  const { colorMode } = useTheme();
  const isLogin = !!getToken();
  const history = useHistory();
  const setIsLoginModalOpen = useSetRecoilState(isLoginModalOpenState);
  const setIsProfileModalOpen = useSetRecoilState(isProfileModalOpenState);
  const onClickVip = useCallback(() => {
    const token = getToken();
    if (token) {
      history.push("/vip");
    } else {
      setIsLoginModalOpen(true);
    }
  }, [history, setIsLoginModalOpen]);
  const onGearClick = () => setIsProfileModalOpen(true);
  return (
    <>
      <header
        id="navbar"
        className={`${shouldSetBgc && "background"} ${colorMode}-mode navbar`}
      >
        <Link to="/">
          <img
            className="desktop-navbar-logo"
            src="/img/logo/logo_black.png"
            alt="logo"
          />
        </Link>
        <div className="desktop-navbar-items">
          <Link to="/">
            <img src="/img/header/home.svg" alt="" />
          </Link>
          <Link to="/about">
            <img src="/img/header/about.svg" alt="" />
          </Link>
          <Link to="/history">
            <img src="/img/header/history.svg" alt="" />
          </Link>
          <Link to="/discography">
            <img src="/img/header/discography.svg" alt="" />
          </Link>
          <div onClick={onClickVip} className="cursor-pointer">
            <img src="/img/header/viproom.svg" alt="" />
          </div>
          <Link to="/video">
            <img src="/img/header/video.svg" alt="" />
          </Link>
        </div>
        {isLogin ? (
          <img
            className="gear-icon"
            src="/img/header/gear_icon.svg"
            alt=""
            onClick={onGearClick}
          />
        ) : (
          <div />
        )}
      </header>
    </>
  );
};

export default Navbar;
