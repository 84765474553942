const MerchBanner = ({ album, merchIndex }) => {
  const { merchs } = album;
  return (
    <div
      className="w-full"
      style={{
        background: `center / cover no-repeat url('${merchs[merchIndex]}')`,
      }}
      alt=""
    />
  );
};

export default MerchBanner;
