import { atom } from "recoil";

export const enteredState = atom({
  key: "entered",
  default: false,
});

export const isHomeModelLoaded = atom({
  key: "isHomeModelLoaded",
  default: false,
});

export const isMenuOpenState = atom({
  key: "isMenuOpen",
  default: false,
});

export const isLoginModalOpenState = atom({
  key: "isLoginModalOpen",
  default: false,
});

export const isRegisterModalOpenState = atom({
  key: "isRegisterModalOpen",
  default: false,
});

export const isProfileModalOpenState = atom({
  key: "isProfileModalOpen",
  default: false,
});

export const colorMode = atom({
  key: "colorMode",
  default: "white",
});
