import { useEffect, useRef, useState, useCallback } from "react";
import { ReactComponent as RealOptionPC } from "../../asset/history_real_options_pc.svg";
import { ReactComponent as FakeOptionPC } from "../../asset/history_fake_options_pc.svg";
import { SpriteAnimator } from "react-sprite-animator";
import TrunkPC from "./TrunkPC";
import { useTheme } from "../../util";

import { animationData } from "../../constant/history";
import bodymovin from "bodymovin";

const ANIM_DURATION = 1000;

const PC = ({ isReal, setIsReal, windowWidth }) => {
  const { setColorMode } = useTheme();
  const [isTransitionShow, setIsTransitionShow] = useState(false);
  const [clicked, setClicked] = useState(false); // user interacted with real/fake controller
  const animContainer = useRef(null);
  const trunkRef = useRef(null);
  const mirrorRef = useRef(null);
  const [centerThingTop, setCenterThingTop] = useState("0px");
  const onMirrorLoad = useCallback(() => {
    const top = 0.29 * mirrorRef.current.height + "px";
    setCenterThingTop(top);
  }, []);
  useEffect(() => {
    const top = 0.29 * mirrorRef.current.height + "px";
    setCenterThingTop(top);
  }, [windowWidth]);
  useEffect(() => {
    // set animation
    const animSetting = {
      container: animContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };
    const animation = bodymovin.loadAnimation(animSetting);
    const playAnimation = () => {
      setIsTransitionShow(true);
      animation.setDirection(1);
      animation.play();
      setTimeout(() => {
        setIsTransitionShow(false);
        animation.resetSegments();
      }, 1000); // after duration 1s, hide the transition screen
    };

    // set button events
    let realButton;
    let fakeButton;
    const toReal = () => {
      const { offsetTop } = trunkRef.current;
      if (isReal) {
        setClicked(true); // default is 'real', simply shows the trunk when user clicked the button
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
        return;
      }
      playAnimation();
      setTimeout(() => {
        setColorMode("white");
        setIsReal(true);
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }, ANIM_DURATION - 200);
    };
    const toFake = () => {
      const { offsetTop } = trunkRef.current;
      if (!isReal) return;
      playAnimation();
      setTimeout(() => {
        setColorMode("black");
        setClicked(true);
        setIsReal(false);
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }, ANIM_DURATION - 200);
    };
    if (isReal) {
      realButton = document.getElementById("pc_real_real_button");
      fakeButton = document.getElementById("pc_real_fake_button");
    } else {
      realButton = document.getElementById("pc_fake_real_button");
      fakeButton = document.getElementById("pc_fake_fake_button");
    }

    if (realButton) {
      realButton.style.cursor = "pointer";
      realButton.addEventListener("click", toReal);
    }
    if (fakeButton) {
      fakeButton.style.cursor = "pointer";
      fakeButton.addEventListener("click", toFake);
    }
    return () => {
      if (realButton) realButton.removeEventListener("click", toReal);
      if (fakeButton) fakeButton.removeEventListener("click", toFake);
    };
  }, [isReal, setIsReal, setColorMode]);
  return (
    <div>
      <div
        ref={animContainer}
        className={`anim-container ${!isTransitionShow ? "hide" : ""}`}
      ></div>
      <img src="/img/history/transparent_bg_pc.png" alt="" />
      {isReal ? (
        <img
          src="/img/history/real/history_bg_pc.png"
          alt=""
          className="absolute top-0 left-0"
        />
      ) : (
        <img
          src="/img/history/fake/history_bg_pc.png"
          alt=""
          className="absolute top-0 left-0"
        />
      )}
      {isReal ? (
        <img
          src="/img/history/real/deco_pc.svg"
          className="absolute top-0 left-0"
          alt=""
        />
      ) : (
        <img
          src="/img/history/real/deco_pc.svg"
          className="absolute top-0 left-0"
          alt=""
        />
      )}
      <img
        src="/img/history/light-bottom.png"
        alt=""
        className="absolute top-0 left-0 half-fade-delay-2"
      />
      {isReal ? (
        <img
          ref={mirrorRef}
          onLoad={onMirrorLoad}
          src="/img/history/real/mirror_pc.png"
          alt=""
          className="absolute top-0 left-0"
        />
      ) : (
        <img
          src="/img/history/fake/mirror_pc.png"
          alt=""
          className="absolute top-0 left-0"
        />
      )}
      <div
        className="absolute left-1/2 transform -translate-x-1/2"
        style={{ top: centerThingTop }}
      >
        <SpriteAnimator
          sprite="/img/history/center_thing.png"
          width={250}
          height={200}
          scale={(window.innerHeight * 3) / windowWidth}
          fps={10}
          frameCount={50}
          wrapAfter={10}
        />
      </div>
      {clicked && isReal && (
        <img
          src="/img/history/real/badge_pc.png"
          alt=""
          className="absolute top-0 left-0 z-10"
        />
      )}
      {clicked && !isReal && (
        <img
          src="/img/history/fake/badge_pc.png"
          alt=""
          className="absolute top-0 left-0 z-10"
        />
      )}
      <img
        src="/img/history/light-top.png"
        alt=""
        className="absolute top-0 left-0 half-fade-delay-1"
      />
      <img
        src="/img/history/light-sparkle.png"
        alt=""
        className="absolute top-0 left-0 half-fade"
      />
      {isReal ? (
        <RealOptionPC className="absolute top-0 left-0 z-20" />
      ) : (
        <FakeOptionPC className="absolute top-0 left-0 z-20" />
      )}
      <div id="trunk" ref={trunkRef}>
        {clicked && <TrunkPC windowWidth={windowWidth} isReal={isReal} />}
      </div>
    </div>
  );
};

export default PC;
