import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PC from "./video/PC";
import Mobile from "./video/Mobile";
import { mobileControllerWidth } from "../constant";
import { client } from "../client";
import { VIDEOS_DEFAULT } from "../constant";

const ControlPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [videoIndex, setVideoIndex] = useState(0);
  const [videos, setVideos] = useState(VIDEOS_DEFAULT);
  useEffect(() => {
    const fetchData = async () => {
      const res = await client.getEntry("5HxLGikdZfgMQ8t03ueUj5");
      setVideos(res.fields.videos.videos);
    };
    try {
      fetchData();
    } catch {
      setVideos(VIDEOS_DEFAULT);
    }
  }, []);

  useEffect(() => {
    const onResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });
  // show PC view when windowWidth is greater than 2 mobile controller with 100px buffer
  const isPCView = windowWidth > 2 * mobileControllerWidth + 100;
  return (
    <div className="overflow-x-hidden pb-20 bg-white">
      <Helmet>
        <title>Amazing Show | Control Room</title>
      </Helmet>
      {isPCView ? (
        <PC
          windowWidth={windowWidth}
          videoIndex={videoIndex}
          setVideoIndex={setVideoIndex}
          videos={videos}
        />
      ) : (
        <Mobile
          windowWidth={windowWidth}
          videoIndex={videoIndex}
          setVideoIndex={setVideoIndex}
          videos={videos}
        />
      )}
    </div>
  );
};

export default ControlPage;
