import { useEffect, useState } from "react";
import { ReactComponent as HistoryRealTrunkMobile } from "../../asset/history_real_trunk_mobile.svg";
import { ReactComponent as HistoryFakeTrunkMobile } from "../../asset/history_fake_trunk_mobile.svg";
import { getRectStyle } from "../../util";

const all = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const TrunkMobile = ({ windowWidth, isReal }) => {
  const [frameStyle, setFrameStyle] = useState([]);
  useEffect(() => {
    const arr = all.map((_, index) =>
      getRectStyle("mobile", isReal, index + 1)
    );
    setFrameStyle(arr);
    return () => {};
  }, [isReal, windowWidth]);
  return (
    <div>
      {isReal ? (
        <HistoryRealTrunkMobile className="relative z-0" />
      ) : (
        <HistoryFakeTrunkMobile className="relative z-0" />
      )}
      {frameStyle.map((i, index) => (
        <img
          key={index}
          className="absolute"
          style={i}
          src={`/img/history/${isReal ? "real" : "fake"}/${index + 1}.png`}
          alt=""
        />
      ))}
    </div>
  );
};

export default TrunkMobile;
