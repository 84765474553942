import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PC from "./discography/PC";
import Tablet from "./discography/Tablet";

const DiscographyPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const onResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });
  const isPCView = windowWidth > 900;
  return (
    <div className="text-white">
      <Helmet>
        <title>Amazing Show | Discography</title>
      </Helmet>
      {isPCView ? <PC /> : <Tablet />}
    </div>
  );
};

export default DiscographyPage;
