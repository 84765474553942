import { Raycaster, Box3 } from "three";
import { BackgroundSound } from "../../util";

export class Instrument {
  constructor(id, soundKeys) {
    this.id = id;
    this.gltf = null;
    this.body = null;
    this.objects = null;
    this.target = null;
    this.animations = null;
    this.animationMixer = null;
    this.animationAction = null;
    this.raycaster = new Raycaster();
    this.backgroundSoundMap = soundKeys.reduce((acc, cur) => {
      acc[cur] = new BackgroundSound(cur);
      return acc;
    }, {});
    this.box = new Box3();
    this.instrumentsInfo = null;
  }
  setAxisCenter(box, object) {
    box.setFromObject(object);
    box.getCenter(object.position); // this re-sets the mesh position
    object.position.multiplyScalar(-1);
  }
  playSound() {
    const instrument = this.instrumentsInfo[this.target];
    if (instrument) {
      this.backgroundSoundMap[instrument.sound].play();
    }
  }
  stopSound() {
    const instrument = this.instrumentsInfo[this.target];
    if (instrument) {
      this.backgroundSoundMap[instrument.sound].stop();
    }
  }
  stopAnimation() {
    this.animationMixer.stopAllAction();
    this.animationMixer.uncacheAction();
  }
}
