import { useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import CloseButton from "../common/CloseButton";
import ProfileForm from "../profile-modal/ProfileForm";
import { isRegisterModalOpenState } from "../../atom";
import {
  desktopProfileModalStyle,
  mobileProfileModalStyle,
} from "../../constant";
import { isMobile } from "react-device-detect";
import { useAuthContext } from "../../util";

const RegisterModal = () => {
  const { t } = useTranslation();
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useRecoilState(
    isRegisterModalOpenState
  );
  const modalStyle = useMemo(() => {
    return isMobile ? mobileProfileModalStyle : desktopProfileModalStyle;
  }, []);
  const closeButtonPosition = useMemo(() => {
    return isMobile ? "top-8 right-8" : "top-6 right-10";
  }, []);

  const history = useHistory();
  const { register, loginType } = useAuthContext();

  const onRegister = useCallback(
    (userData) => {
      const callback = () => {
        setIsRegisterModalOpen(false);
        history.push("/vip");
      };
      register(loginType, userData, callback);
    },
    [history, loginType, register, setIsRegisterModalOpen]
  );
  return (
    <Modal style={modalStyle} isOpen={isRegisterModalOpen}>
      <div className={`absolute ${closeButtonPosition}`}>
        <CloseButton onClose={() => setIsRegisterModalOpen(false)} />
      </div>
      <div>{t("signup")}</div>
      <ProfileForm submit={onRegister} submitText={t("save")} />
    </Modal>
  );
};
export default RegisterModal;
