import Modal from "react-modal";
import Player from "./Player";
import CloseButton from "../common/CloseButton";
import { playerModal } from "../../constant";

const PlayerModal = ({ isOpen, onClose }) => {
  return (
    <Modal onRequestClose={onClose} style={playerModal} isOpen={isOpen}>
      <div className="flex flex-col">
        <img src="/img/viproom/player_top.svg" alt="" />
        <div className="flex justify-between my-3">
          <img
            src="/img/viproom/player_left.svg"
            alt=""
            className="hidden sm:block"
          />
          <div className="flex flex-col flex-1 mx-2">
            <div className="relative">
              <h1 className="text-lg font-bold my-3 flex-1 flex justify-center items-center">
                登山寶訓
              </h1>
              <div className="absolute right-0 top-0">
                <CloseButton isRounded={true} onClose={onClose} />
              </div>
            </div>
            <Player />
          </div>
          <img
            src="/img/viproom/player_right.svg"
            alt=""
            className="hidden sm:block"
          />
        </div>
        <img src="/img/viproom/player_bottom.svg" alt="" />
      </div>
    </Modal>
  );
};
export default PlayerModal;
