import { useState, useEffect } from "react";

const Select = ({ value, setValue, options }) => {
  const [hasSelectedValue, setHasSelectedValue] = useState(false);

  useEffect(() => {
    if (value) setHasSelectedValue(true);
  }, [value]);

  const onChange = (e) => {
    if (e.target.value) {
      setValue(e.target.value);
      setHasSelectedValue(true);
    }
  };
  return (
    <div className="selector">
      <select
        className={`${!hasSelectedValue && "select-placeholder"} modal-input`}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <option
            key={option}
            value={index}
            hidden={index === 0}
            disabled={index === 0}
            selected={value ? index === value : index === 0}
            className="checkmark ml-2 cursor-pointer"
          >
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
