import axios from "axios";
import { getToken, setToken } from "../util";
import { API_HOST } from "../constant";

export const apiCall = async ({
  url = "/",
  method = "get",
  data = {},
  contentType = "",
  onSuccess = (d) => {},
  onError = () => {},
}) => {
  try {
    url = `${API_HOST}/${url}`;
    const token = getToken();
    const _token = token ? { Authorization: `Bearer ${token}` } : {};
    const _contentType = contentType ? { "Content-Type": contentType } : {};
    const headers = { ..._token, ..._contentType };
    // @ts-ignore
    const res = await axios({ method, url, data, headers });
    if (res.status === 200) {
      onSuccess(res.data);
      const { token } = res.headers;
      if (token) setToken(token);
    }
  } catch (err) {
    onError(err.response);
  }
};
