import { useState } from "react";
import { PCAlbumCard, MerchBanner, MerchList } from "../../components";
import { albums } from "../../constant";

const PC = () => {
  const [albumIndex, setAlbumIndex] = useState(0);
  const [merchIndex, setMerchIndex] = useState(0);
  return (
    <div className="discography-tablet">
      <img className="w-full" src="/img/discography/landing_pc.png" alt="" />
      <div
        className="flex flex-wrap justify-center bg-no-repeat"
        style={{ backgroundImage: `url('/img/discography/purple.svg')` }}
      >
        {albums.map((a, index) => (
          <PCAlbumCard
            album={a}
            onClick={() => {
              setAlbumIndex(index);
              setMerchIndex(0);
            }}
          />
        ))}
      </div>
      <img
        src="/img/discography/middle_divider.svg"
        alt=""
        className="px-8 my-8"
      />
      <div className="merch-section flex mx-10 justify-between">
        <div className="flex justify-center w-1/2">
          <MerchBanner album={albums[albumIndex]} merchIndex={merchIndex} />
        </div>
        <MerchList album={albums[albumIndex]} onClick={setMerchIndex} />
        <div className="flex flex-1 justify-center">
          <img
            className=""
            src={`/img/discography/${albums[albumIndex].imgUrl}_desc.svg`}
            alt=""
          />
        </div>
      </div>
      <img src="/img/discography/footer.svg" alt="" className="my-10" />
    </div>
  );
};

export default PC;
