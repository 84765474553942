import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  DesktopMemberBanner,
  DesktopMemberRow,
  DesktopMemberIntro,
} from "../components";

const AboutPage = () => {
  const [memberIndex, setMemberIndex] = useState(0);
  const [, setClicked] = useState(false); // show member intro after user interacts
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const onResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });
  const isPCView = windowWidth > 1200;
  const isTablet = windowWidth < 800;
  return (
    <div className="about">
      <Helmet>
        <title>Amazing Show | About</title>
      </Helmet>
      <DesktopMemberBanner isTablet={isTablet} />
      <DesktopMemberRow
        isPCView={isPCView}
        scrollTop={0}
        setMemberIndex={(index) => {
          setMemberIndex(index);
          setClicked(true);
          window.setTimeout(() => {
            // [todo] 第一次沒辦法滾到底
            let offsetTop = 5000;
            const elem = document.getElementById("member-intro");
            if (elem) offsetTop = elem.offsetTop;
            window.scrollTo({ top: offsetTop - 100, behavior: "smooth" });
          }, 0);
        }}
      />
      <DesktopMemberIntro
        isPCView={isPCView}
        memberIndex={memberIndex}
        clicked={true}
      />
    </div>
  );
};

export default AboutPage;
