const MerchList = ({ album, onClick }) => {
  const { merchs } = album;
  return (
    <div className="flex flex-col mx-4 w-40 overflow-y-scroll">
      {merchs.map((m, index) => (
        <img
          className="mb-3 cursor-pointer"
          src={m}
          alt=""
          onClick={() => onClick(index)}
        />
      ))}
    </div>
  );
};

export default MerchList;
