import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

const PolicyRow = ({ closeModal }) => {
  return (
    <div className="my-4 text-sm break-all">
      <Trans
        i18nKey="i_agree_terms_privacy"
        components={[
          <Link
            className="policy-link pl-2"
            to="policy"
            onClick={closeModal}
          ></Link>,
          <span className="mx-2">&</span>,
          <Link className="policy-link" to="terms" onClick={closeModal}></Link>,
        ]}
      />
    </div>
  );
};

export default PolicyRow;
