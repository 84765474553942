import { useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { instrumentScript } from "../constant/tv";
import { InstrumentCanvas, Delayed, TV } from "../components";
import { useAuthContext } from "../util";

const InstrumentPage = () => {
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    const validId = [1, 2, 3];
    const instrumentId = parseInt(id);
    if (!validId.includes(instrumentId)) history.goBack();
  }, [history, id]);

  const { checkAuth } = useAuthContext();
  useEffect(() => {
    if (
      process.env.NODE_ENV === "development" ||
      process.env.TOKEN_CHECK_ENABLED === "false"
    )
      return; // skip token check in development mode
    checkAuth();
  }, [history, checkAuth]);
  return (
    <div>
      <Helmet>
        <title>Amazing Show | Instrument</title>
      </Helmet>
      <InstrumentCanvas id={id} />
      <Delayed waitBeforeShow={3000}>
        <TV
          script={instrumentScript}
          interval={5000}
          hasBackground={false}
          position="bottom-4 sm:left-4"
          callback={() => history.push("/vip")}
        />
      </Delayed>
    </div>
  );
};

export default InstrumentPage;
