import { useLocation } from "react-router";
import { useTheme } from "../util";

const Footer = () => {
  const { colorMode } = useTheme();
  const location = useLocation();
  if (location.pathname === "/vip") return null;
  if (location.pathname.includes("instrument")) return null;

  return (
    <footer className={`footer ${colorMode}-mode`}>
      <div className="footer-main">
        <img
          className="footer-deco"
          src={`/img/footer/deco_star_top_${colorMode}.svg`}
          alt="deco star"
        />
        <div className="footer-content">
          <div className="footer-contact">
            <img
              className="footer-qrcode"
              src="/img/footer/footer_qrcode.png"
              alt="qrcode"
            />
            <div className="footer-email">
              <div className="footer-email-title footer-text">
                BUSINESS CONTACT
                <img
                  className="footer-arrow"
                  alt="arrow right"
                  src="/img/footer/arrow_right.svg"
                />
              </div>
              <a
                className="footer-text email"
                href="mailto:amazingshowcy@gmail.com"
              >
                amazingshowcy@gmail.com
              </a>
            </div>
          </div>
          <div className="footer-social">
            <a
              className="footer-social-row"
              href="https://www.facebook.com/amazingshowtw"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="footer-social-icon"
                alt="fb icon"
                src={`/img/icon/social/facebook_${colorMode}.svg`}
              />
              Facebook
            </a>
            <a
              className="footer-social-row"
              href="https://www.instagram.com/amazingshowtw"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="footer-social-icon"
                alt="ig icon"
                src={`/img/icon/social/instagram_${colorMode}.svg`}
              />
              Instagram
            </a>
            <a
              className="footer-social-row"
              href="https://www.youtube.com/c/%E7%BE%8E%E7%A7%80%E9%9B%86%E5%9C%98AmazingShow/featured"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="footer-social-icon"
                alt="yt icon"
                src={`/img/icon/social/youtube_${colorMode}.svg`}
              />
              YouTube
            </a>
          </div>
        </div>
        <img
          className="footer-deco"
          src={`/img/footer/deco_star_bottom_${colorMode}.svg`}
          alt="deco star"
        />
      </div>
      <div className={`footer-copyright ${colorMode}-mode`}>
        <span className="copyright-text">
          ©2021 AMAZINGSHOW co,.Ltd. All rights reserved.
        </span>
        <span className="copyright-text">
          ©2021 AMAZINGSHOW co,.Ltd. All rights reserved.
        </span>
        <span className="copyright-text">
          ©2021 AMAZINGSHOW co,.Ltd. All rights reserved.
        </span>
      </div>
    </footer>
  );
};
export default Footer;
