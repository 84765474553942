import { useEffect } from "react";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { Room } from "../components";
import { getToken, useAuthContext } from "../util";

const VIPPage = () => {
  const history = useHistory();
  const { checkAuth } = useAuthContext();
  useEffect(() => {
    if (
      process.env.NODE_ENV === "development" ||
      process.env.TOKEN_CHECK_ENABLED === "false"
    )
      return; // skip token check in development mode
    // check login or not
    const token = getToken();
    if (!token) return history.push("/");
    checkAuth();
  }, [history, checkAuth]);
  return (
    <div>
      <Helmet>
        <title>Amazing Show | VIP</title>
      </Helmet>
      <Room />
    </div>
  );
};

export default VIPPage;
