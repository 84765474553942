import { useEffect, useRef, useState } from "react";
import TrunkMobile from "./TrunkMobile";
import { SpriteAnimator } from "react-sprite-animator";
import { ReactComponent as HistoryRealLandingMobile } from "../../asset/history_real_landing_mobile.svg";
import { ReactComponent as HistoryFakeLandingMobile } from "../../asset/history_fake_landing_mobile.svg";
import { useTheme } from "../../util";

import { animationData } from "../../constant/history";
import bodymovin from "bodymovin";

const ANIM_DURATION = 1000;

const Mobile = ({ isReal, setIsReal, windowWidth }) => {
  const { setColorMode } = useTheme();
  const [isTransitionShow, setIsTransitionShow] = useState(false);
  const [clicked, setClicked] = useState(false); // user interacted with real/fake controller
  const animContainer = useRef(null);
  const [centerThingTop, setCenterThingTop] = useState("0px");
  useEffect(() => {
    const circleSelector = isReal
      ? "#real-center-circle"
      : "#fake-center-circle";
    const centerCircle = document.querySelector(circleSelector);
    const circleRect = centerCircle.getBoundingClientRect();
    const top = circleRect.y + 55 + "px";
    setCenterThingTop(top);
  }, [isReal, windowWidth]);
  useEffect(() => {
    // set animation
    const animSetting = {
      container: animContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };
    const animation = bodymovin.loadAnimation(animSetting);
    const playAnimation = () => {
      setIsTransitionShow(true);
      animation.setDirection(1);
      animation.play();
      setTimeout(() => {
        setIsTransitionShow(false);
        animation.resetSegments();
      }, 1000); // after duration 1s, hide the transition screen
    };

    // set button events
    let realButton;
    let fakeButton;
    const historyControl = document.getElementById("trunk");
    const { offsetTop } = historyControl;
    const toReal = () => {
      if (isReal) {
        setClicked(true); // default is 'real', simply shows the trunk when user clicked the button
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
        return;
      }
      playAnimation();
      setTimeout(() => {
        setColorMode("white");
        setIsReal(true);
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }, ANIM_DURATION - 200);
    };
    const toFake = () => {
      if (!isReal) return;
      playAnimation();
      setTimeout(() => {
        setColorMode("black");
        setClicked(true);
        setIsReal(false);
        window.scrollTo({ top: offsetTop, behavior: "smooth" });
      }, ANIM_DURATION - 200);
    };
    if (isReal) {
      realButton = document.getElementById("mobile_real_real_button");
      fakeButton = document.getElementById("mobile_real_fake_button");
    } else {
      realButton = document.getElementById("mobile_fake_real_button");
      fakeButton = document.getElementById("mobile_fake_fake_button");
    }

    if (realButton) {
      realButton.style.cursor = "pointer";
      realButton.addEventListener("click", toReal);
    }
    if (fakeButton) {
      fakeButton.style.cursor = "pointer";
      fakeButton.addEventListener("click", toFake);
    }
    return () => {
      if (realButton) realButton.removeEventListener("click", toReal);
      if (fakeButton) fakeButton.removeEventListener("click", toFake);
    };
  }, [isReal, setIsReal, setColorMode]);
  return (
    <div className="pt-5">
      <div
        ref={animContainer}
        className={`anim-container ${!isTransitionShow ? "hide" : ""}`}
      ></div>
      <div
        className="absolute left-1/2 transform -translate-x-1/2"
        style={{ top: centerThingTop }}
      >
        <SpriteAnimator
          sprite="/img/history/center_thing.png"
          width={250}
          height={200}
          scale={(window.innerHeight * 1.25) / windowWidth}
          fps={10}
          frameCount={50}
          wrapAfter={10}
        />
      </div>
      <div>
        {isReal ? <HistoryRealLandingMobile /> : <HistoryFakeLandingMobile />}
      </div>
      <img
        src="/img/history/light-bottom-mobile.png"
        alt=""
        className="absolute top-0 left-0 half-fade pointer-events-none"
      />
      <img
        src="/img/history/light-top-mobile.png"
        alt=""
        className="absolute top-0 left-0 half-fade-delay-1 pointer-events-none"
      />
      <div id="trunk">
        {clicked && <TrunkMobile windowWidth={windowWidth} isReal={isReal} />}
      </div>
    </div>
  );
};

export default Mobile;
