import { MobileAlbumCard } from "../../components";
import { albums } from "../../constant";

const Tablet = () => {
  return (
    <div className="discography-tablet">
      <img
        className="w-full"
        src="/img/discography/landing_mobile.png"
        alt=""
      />
      <div className="flex flex-wrap">
        <MobileAlbumCard album={albums[0]} />
        <img
          className="w-full sm:w-1/2 px-16 sm:pr-12 sm:pl-0"
          src="/img/discography/sound_check_2017_desc.svg"
          alt=""
        />
        <MobileAlbumCard album={albums[1]} />
        <img
          className="w-full sm:w-1/2 px-16 sm:pr-12 sm:pl-0"
          src="/img/discography/dian_huo_desc.svg"
          alt=""
        />
        <MobileAlbumCard album={albums[2]} />
        <img
          className="w-full sm:w-1/2 px-16 sm:pr-12 sm:pl-0"
          src="/img/discography/sound_check_2019_desc.svg"
          alt=""
        />
        <MobileAlbumCard album={albums[3]} />
        <img
          className="w-full sm:w-1/2 px-16 sm:pr-12 sm:pl-0"
          src="/img/discography/duo_se_desc.svg"
          alt=""
        />
      </div>
      <img src="/img/discography/divider.svg" alt="" className="px-8 my-10" />
    </div>
  );
};

export default Tablet;
