import { useTheme } from "../../util";
import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { isMenuOpenState } from "../../atom";

const NavButton = () => {
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(isMenuOpenState);
  const { colorMode } = useTheme();
  const buttonColor = useMemo(() => {
    return colorMode === "white" ? "black" : "white";
  }, [colorMode]);

  const onClick = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen, setIsMenuOpen]);
  const buttonMode = useMemo(() => {
    return isMenuOpen ? "close" : "";
  }, [isMenuOpen]);

  return (
    <div className="nav-button" onClick={onClick}>
      <div className="button-line-container">
        <div className={`${buttonColor}-mode line ${buttonMode}`}></div>
        <div className={`${buttonColor}-mode line ${buttonMode}`}></div>
      </div>
    </div>
  );
};
export default NavButton;
