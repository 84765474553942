import { createContext, useContext, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { colorMode } from "../atom";
import { useLocation } from "react-router";

const ThemeContext = createContext(null);
export const ThemeContextProvider = ({ children }) => {
  const colorModeValue = useRecoilValue(colorMode);
  const setColorMode = useSetRecoilState(colorMode);
  const value = { colorMode: colorModeValue, setColorMode };
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/about" ||
      location.pathname === "/discography"
    ) {
      setColorMode("black");
    } else {
      setColorMode("white");
    }
  }, [location, setColorMode]);
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
