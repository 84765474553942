import { useCallback, useEffect, useMemo, useRef } from "react";
import Slider from "react-slick";
import { ReactComponent as ScriptBg } from "../../asset/script_modal_bg.svg";
import { isMobile } from "react-device-detect";

const sliderSettings = {
  autoplay: false,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  customPaging: function (i) {
    return (
      <div
        className="bg-no-repeat bg-cover"
        style={{
          width: "30px",
          height: "30px",
          backgroundImage: `url('/img/scripts/${i + 1}.jpg')`,
        }}
      />
    );
  },
};

const SCRIPT_NUMBER = 6;
const scriptSources = Array(SCRIPT_NUMBER)
  .fill("")
  .map((_, index) => {
    return `/img/scripts/${index + 1}.jpg`;
  });

const ScriptCarousel = ({ onClose }) => {
  const sliderRef = useRef(null);
  const next = useCallback(
    () => sliderRef.current && sliderRef.current.slickNext(),
    []
  );
  const prev = useCallback(
    () => sliderRef.current && sliderRef.current.slickPrev(),
    []
  );

  useEffect(() => {
    const closeButtonEl = document.querySelector("#close-button");
    closeButtonEl.addEventListener("click", onClose);
    return closeButtonEl.removeEventListener("click", onClose);
  }, [onClose]);

  const styles = useMemo(() => {
    return isMobile
      ? {
          bgWidth: "110%",
          closeBtnTop: "-24px",
          closeBtnRight: "-14px",
        }
      : {
          bgWidth: "470px",
          closeBtnTop: "-39px",
          closeBtnRight: "-23px",
        };
  }, []);

  return (
    <div className="relative w-10/12">
      <div
        onClick={onClose}
        className="absolute cursor-pointer"
        style={{
          width: "16px",
          height: "16px",
          top: styles.closeBtnTop,
          right: styles.closeBtnRight,
        }}
      ></div>
      <ScriptBg
        className="carousel-bg"
        style={{ width: styles.bgWidth, zIndex: -1 }}
      />
      <Slider {...sliderSettings} ref={sliderRef}>
        {scriptSources.map((source, index) => (
          <img key={index} src={source} alt={`script ${index}`} />
        ))}
      </Slider>
      <div className="flex" style={{ zIndex: 1 }}>
        <img
          onClick={prev}
          src="/img/viproom/left_arrow.svg"
          className="script-arrow"
          alt=""
        />
        <div className="flex-1"></div>
        <img
          onClick={next}
          src="/img/viproom/right_arrow.svg"
          className="script-arrow"
          alt=""
        />
      </div>
    </div>
  );
};

export default ScriptCarousel;
