import { useState, useEffect } from "react";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import gsap from "gsap";

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [shouldSetBgc, setShouldSetBgc] = useState(false);
  useEffect(() => {
    const onResize = () => setWindowWidth(window.innerWidth);

    let currentPos = window.pageYOffset;
    let lastPos = 0;
    const onScroll = () => {
      lastPos = currentPos;
      currentPos = window.pageYOffset;

      const timeline = new gsap.timeline();
      if (currentPos > 100) {
        setShouldSetBgc(true);
        if (currentPos - lastPos > 0.5) {
          timeline.to("#navbar", { y: -100, duration: 0.5 });
        } else {
          timeline.to("#navbar", { y: 0, duration: 0.5 });
        }
      } else {
        setShouldSetBgc(false);
      }
    };

    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
  });
  const isPCView = windowWidth > 800;
  return isPCView ? (
    <DesktopNavbar shouldSetBgc={shouldSetBgc} />
  ) : (
    <MobileNavbar shouldSetBgc={shouldSetBgc} />
  );
};

export default Header;
