import { Marquee } from "..";

const DesktopNews = () => {
  return (
    <div className="desktop-only-news">
      <div
        className="top"
        style={{
          background: 'url("/img/home/desert.png") center/cover no-repeat',
        }}
      >
        <img
          className="w-64 absolute bottom-0 left-0"
          src="/img/home/bottom_left_tag.svg"
          alt=""
        />
        <img
          src="/img/home/purple_right_tag.svg"
          alt=""
          className="h-full absolute right-0"
        />
      </div>
      <div className="bottom">
        <div className="news-header">
          <Marquee width="100%">
            <span className="news-title">給你們看天氣圖</span>
          </Marquee>
          <img className="w-48" src="/img/home/top_right_tag.svg" alt="" />
        </div>
        <div className="news-image h-full mt-4">
          <img
            className="absolute w-48 top-0 right-0"
            src="/img/home/globe.svg"
            alt=""
          />
          <iframe
            title="weather"
            class="h-full"
            src="https://wifi.cwa.gov.tw/v2/"
          />
          <img
            className="absolute right-0 bottom-0"
            src="/img/home/star_barcode_tag.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default DesktopNews;
