import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//import translationEN from "./en.json";
import translationTW from "./zh-TW.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  //en: {
  //translation: translationEN,
  //},
  "zh-TW": {
    translation: translationTW,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "zh-TW",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
