import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import Controller from "../../components/video/Controller";
import { ReactComponent as VideoBGPC } from "../../asset/video_bg_pc.svg";
import { pcControllerWidth } from "../../constant";

const PC = ({ videos, windowWidth, videoIndex, setVideoIndex }) => {
  const [playing, setPlaying] = useState(false);
  const [player1Style, setPlayer1Style] = useState({});
  const [player2Style, setPlayer2Style] = useState({});
  const [player3Style, setPlayer3Style] = useState({});
  useEffect(() => {
    // remember to change the id in svg file
    const playVideo = () => setPlaying(!playing);
    const playButton = document.getElementById("pc_play_button");
    playButton.style.cursor = "pointer";
    playButton.addEventListener("click", playVideo);

    const playNextVideo = () => setVideoIndex((videoIndex + 1) % videos.length);
    const nextVideoButton = document.getElementById("pc_next_video_button");
    nextVideoButton.style.cursor = "pointer";
    nextVideoButton.addEventListener("click", playNextVideo);

    const playPrevVideo = () =>
      setVideoIndex(videoIndex - 1 >= 0 ? videoIndex - 1 : videos.length - 1);
    const prevVideoButton = document.getElementById("pc_prev_video_button");
    prevVideoButton.style.cursor = "pointer";
    prevVideoButton.addEventListener("click", playPrevVideo);

    return () => {
      playButton.removeEventListener("click", playVideo);
      nextVideoButton.removeEventListener("click", playNextVideo);
      prevVideoButton.removeEventListener("click", playPrevVideo);
    };
  }, [windowWidth, playing, videoIndex, setVideoIndex, videos]);
  useEffect(() => {
    const videoScreen1 = document.getElementById("pc_screen1");
    const rect1 = videoScreen1.getBoundingClientRect();
    setPlayer1Style({
      width: rect1.width,
      height: rect1.height,
      top: rect1.y + window.pageYOffset,
      left: rect1.x,
    });
    const videoScreen2 = document.getElementById("pc_screen2");
    const rect2 = videoScreen2.getBoundingClientRect();
    setPlayer2Style({
      width: rect2.width,
      height: rect2.height,
      top: rect2.y + window.pageYOffset,
      left: rect2.left,
    });
    const videoScreen3 = document.getElementById("pc_screen3");
    const rect3 = videoScreen3.getBoundingClientRect();
    setPlayer3Style({
      width: rect3.width,
      height: rect3.height,
      top: rect3.y + window.pageYOffset,
      left: rect3.x,
    });
  }, [windowWidth]);
  const controllerCount = Math.floor(windowWidth / pcControllerWidth);
  const _videos =
    videoIndex + controllerCount > videos.length - 1
      ? [
          ...videos.slice(videoIndex + 1),
          ...videos.slice(
            0,
            controllerCount - (videos.length - 1 - videoIndex)
          ),
        ]
      : videos.slice(videoIndex + 1, videoIndex + 1 + controllerCount);
  return (
    <div>
      <div className="control-room relative">
        <img
          src="/img/video/wall_painting_left.png"
          className="absolute"
          style={{
            top: player1Style.top,
            left: player1Style.left,
            width: player1Style.width,
            height: player1Style.height,
          }}
          alt=""
        />
        <ReactPlayer
          className="control-player-2 absolute"
          width={player2Style.width}
          height={player2Style.height}
          style={{
            top: player2Style.top,
            left: player2Style.left,
          }}
          playing={playing}
          controls={true}
          url={videos[videoIndex].url}
        />
        <img
          src="/img/video/wall_painting_right.png"
          className="absolute"
          style={{
            top: player3Style.top,
            left: player3Style.left,
            width: player3Style.width,
            height: player3Style.height,
          }}
          alt=""
        />
      </div>
      <VideoBGPC className="w-full" />
      <div className="flex justify-center">
        <img
          className="absolute left-0 video-bg"
          src="/img/video/video_bg_left.svg"
          alt=""
        />
        {_videos.map((v) => (
          <Controller
            key={v.index}
            {...v}
            setVideoIndex={setVideoIndex}
            isPCView={true}
          />
        ))}
        <img
          className="absolute right-0 video-bg"
          src="/img/video/video_bg_right.svg"
          alt=""
        />
      </div>
    </div>
  );
};

export default PC;
