import { useMemo } from "react";
import { isMobile } from "react-device-detect";

const CloseButton = ({ onClose, isRounded = false }) => {
  const styleConfig = useMemo(() => {
    return isMobile ? { size: "h-6 w-6" } : { size: "h-4 w-4" };
  }, []);
  const buttonClass = isRounded
    ? "p-1 m-3 border border-white border-solid rounded-full"
    : "p-4";
  return (
    <div className={`z-10 cursor-pointer ${buttonClass}`} onClick={onClose}>
      <svg className={`fill-current ${styleConfig.size}`} viewBox="0 0 20 20">
        <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
      </svg>
    </div>
  );
};

export default CloseButton;
