import { useEffect, useState } from "react";
import { ReactComponent as HistoryRealTrunkPC } from "../../asset/history_real_trunk_pc.svg";
import { ReactComponent as HistoryFakeTrunkPC } from "../../asset/history_fake_trunk_pc.svg";
import { getRectStyle } from "../../util";

const all = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const TrunkPC = ({ windowWidth, isReal }) => {
  const [frameStyle, setFrameStyle] = useState([]);
  useEffect(() => {
    const arr = all.map((i) => getRectStyle("pc", isReal, i));
    setFrameStyle(arr);
    return () => {};
  }, [isReal, windowWidth]);
  return (
    <div>
      {isReal ? (
        <HistoryRealTrunkPC className="relative z-0" />
      ) : (
        <HistoryFakeTrunkPC className="relative z-0" />
      )}
      {frameStyle.map((i, index) => (
        <img
          key={index}
          className="absolute"
          style={i}
          src={`/img/history/${isReal ? "real" : "fake"}/${index + 1}.png`}
          alt=""
        />
      ))}
    </div>
  );
};

export default TrunkPC;
