import GoogleLogin from "react-google-login";
import { GOOGLE_CLIENT_ID } from "../../constant";
import LoginButton from "./LoginButton";

const GoogleLoginButton = ({ login }) => {
  const responseGoogle = (response) => {
    const { tokenId, profileObj } = response;
    const { name } = profileObj;
    login("google", tokenId, name);
  };
  return (
    <LoginButton loginType="google">
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText=""
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </LoginButton>
  );
};

export default GoogleLoginButton;
