export const mobileModalStyle = {
  content: {
    width: "100vw",
    height: "100vh",
    padding: "56px 30px 30px",
    top: 0,
    left: 0,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    border: "none",
    borderRadius: "0px",
  },
};
export const desktopModalStyle = {
  content: {
    width: "400px",
    height: "362px",
    margin: "auto",
    padding: "40px",
    overflow: "hidden",
    color: "white",
    backgroundColor: "black",
    border: "none",
  },
  overlay: {
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
};

export const desktopProfileModalStyle = {
  content: {
    width: "400px",
    height: "785px",
    margin: "auto",
    padding: "40px",
    overflow: "auto",
    color: "white",
    backgroundColor: "black",
    border: "none",
  },
  overlay: {
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
};

export const mobileProfileModalStyle = {
  content: {
    width: "100vw",
    height: "100vh",
    padding: "56px 30px 80px",
    top: 0,
    left: 0,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    border: "none",
    borderRadius: "0px",
    overflow: "auto",
  },
};

export const desktopScriptModal = {
  content: {
    width: "500px",
    height: "100%",
    color: "white",
    margin: "auto",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

export const mobileScriptModal = {
  content: {
    width: "100%",
    height: "100%",
    margin: "auto",
    color: "white",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  overlay: {
    backgroundColor: "transparent",
  },
};

export const playerModal = {
  content: {
    width: "100%",
    height: "100%",
    margin: "auto",
    overflow: "auto",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    left: 0,
  },
  overlay: {
    backgroundColor: "transparent",
  },
};
