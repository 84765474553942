export const pcControllerWidth = 400;
export const pcControllerHeight = 830;
export const mobileControllerWidth = 187;
export const mobileControllerHeight = 383;

export const pcControllerVideoWidth = 360;
export const pcControllerVideoHeight = 238;
export const mobileControllerVideoWidth = 171;
export const mobileControllerVideoHeight = 113;

export const pcMonitorWidth = 241;
export const pcMonitorHeight = 284;
export const mobileMonitorWidth = 94;
export const mobileMonitorHeight = 101;

export const pcMonitorVideoWidth = 200;
export const pcMonitorVideoHeight = 156;
export const mobileMonitorVideoWidth = 85;
export const mobileMonitorVideoHeight = 56;

export const videos = [
  {
    index: 0,
    videoId: "ouQwJj1V2pE",
    url: "https://www.youtube.com/watch?v=ouQwJj1V2pE",
    title: "我要你愛 You are My Crazy Lover",
    desc: "愛是恆久忍耐，又有恩慈；愛是不嫉妒；愛是不自誇不張狂，不做害羞的事⋯⋯又或許，你想跟我做害羞的事？",
  },
  {
    index: 1,
    videoId: "j1U1NLkx5XM",
    url: "https://www.youtube.com/watch?v=j1U1NLkx5XM",
    title: "殭屍王 The Ultimate Virus",
    desc: "人活著，爭的是一口氣，人死了，就爭不得那口氣。若你還是要爭，多憋了一口氣，死不斷氣，便是殭屍。",
  },
  {
    index: 2,
    videoId: "wBScjyf6qns",
    url: "https://www.youtube.com/watch?v=wBScjyf6qns",
    title: "馬克吐溫 Mark Twain",
    desc: "語言開始失靈。",
    margin: true,
  },
  {
    index: 3,
    videoId: "oUEV2YgmWsg",
    url: "https://www.youtube.com/watch?v=oUEV2YgmWsg",
    title: "【八寶山：奪寶之路】",
    desc: "Άλφα 時間線：Error 404（Woops.Looks like this page doesn't exist）",
    margin: true,
  },
  {
    index: 4,
    videoId: "qPV7iHdbFc0",
    url: "https://www.youtube.com/watch?v=qPV7iHdbFc0",
    title: "美秀電器_002「炫炮」",
    desc: "《炫炮》是一台融合合成器、效果器與旋轉燈箱於一身的低科技炫炮裝置樂器，可以彈、可以吹也可以插。",
    margin: true,
  },
  {
    index: 5,
    videoId: "_QjLBxeTk5E",
    url: "https://www.youtube.com/watch?v=_QjLBxeTk5E",
    title: "美秀電器_001「台八線」",
    desc: "台八線，是一台透過八條雷射光同時控制外圍七彩燈光與聲音的「低科技妖媚裝置樂器」，可彈、可舞、可推，透過肢體各部位的協調性及韻律性，",
  },
  {
    index: 6,
    videoId: "DTKC8394Mr4",
    url: "https://www.youtube.com/watch?v=DTKC8394Mr4",
    title: "「炫炮專輯」SUPER POWER ALBUM",
    desc: "炫炮專輯，是美秀電器近期推出的掌上自製合成器，炫炮專輯的兩大特色，在於「口袋攜帶、單手掌握」的輕巧性",
  },
];
