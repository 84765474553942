export const VIP_MARQUEE_DEFAULT = {
  marquee1: "點擊前方電視",
  marquee2: "前往全息演唱會",
  tvLink: "https://www.fireonshop.com/products/amazing-show-paramount",
};

export const FIRST_NEWS_DEFAULT = {
  title: "實體專輯預購中",
  desc: "天地脈動精體神合一多色能量寶塔祝你順心",
  imgUrls: [
    "/img/home/news_banner1.png",
    "/img/home/news_banner2.png",
    "/img/home/news_banner3.png",
    "/img/home/news_banner4.png",
    "/img/home/news_banner5.png",
    "/img/home/news_banner6.png",
  ],
};

export const SECOND_NEWS_DEFAULT = {
  desc: "雙倍奉還 with 大嘻哈時代詳情請上",
  imgUrl: "/img/home/second_news_image2.jpg",
  linkText: "BEATDAY",
  linkUrl: "https://beatday.com/beta-test-ticket/",
  title: "全息演唱會重啟",
};

export const VIDEOS_DEFAULT = [
  {
    desc: "愛是恆久忍耐，又有恩慈；愛是不嫉妒；愛是不自誇不張狂，不做害羞的事⋯⋯又或許，你想跟我做害羞的事？",
    title: "我要你愛 You are My Crazy Lover",
    url: "https://www.youtube.com/watch?v=ouQwJj1V2pE",
    videoId: "ouQwJj1V2pE",
  },
  {
    desc: "人活著，爭的是一口氣，人死了，就爭不得那口氣。若你還是要爭，多憋了一口氣，死不斷氣，便是殭屍。",
    title: "殭屍王 The Ultimate Virus",
    url: "https://www.youtube.com/watch?v=j1U1NLkx5XM",
    videoId: "j1U1NLkx5XM",
  },
  {
    desc: "語言開始失靈。",
    title: "馬克吐溫 Mark Twain",
    url: "https://www.youtube.com/watch?v=wBScjyf6qns",
    videoId: "wBScjyf6qns",
  },
  {
    desc: "Άλφα 時間線：Error 404（Woops.Looks like this page doesn't exist）",
    title: "【八寶山：奪寶之路】",
    url: "https://www.youtube.com/watch?v=oUEV2YgmWsg",
    videoId: "oUEV2YgmWsg",
  },
  {
    desc: "《炫炮》是一台融合合成器、效果器與旋轉燈箱於一身的低科技炫炮裝置樂器，可以彈、可以吹也可以插。",
    title: "美秀電器_002「炫炮」",
    url: "https://www.youtube.com/watch?v=qPV7iHdbFc0",
    videoId: "qPV7iHdbFc0",
  },
  {
    desc: "台八線，是一台透過八條雷射光同時控制外圍七彩燈光與聲音的「低科技妖媚裝置樂器」，可彈、可舞、可推，透過肢體各部位的協調性及韻律性，",
    title: "美秀電器_001「台八線」",
    url: "https://www.youtube.com/watch?v=_QjLBxeTk5E",
    videoId: "_QjLBxeTk5E",
  },
  {
    desc: "炫炮專輯，是美秀電器近期推出的掌上自製合成器，炫炮專輯的兩大特色，在於「口袋攜帶、單手掌握」的輕巧性",
    title: "「炫炮專輯」SUPER POWER ALBUM",
    url: "https://www.youtube.com/watch?v=DTKC8394Mr4",
    videoId: "DTKC8394Mr4",
  },
];
