import { useRef, useEffect, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Vector2,
  Vector3,
  Raycaster,
  Scene,
  Color,
  PerspectiveCamera,
  Group,
  AmbientLight,
  SpotLight,
  PlaneGeometry,
  MeshBasicMaterial,
  MeshPhongMaterial,
  DoubleSide,
  TextureLoader,
  Mesh,
  SphereGeometry,
  WebGLRenderer,
} from "three";
import {
  getToken,
  playSoundByKey,
  BackgroundSound,
  onWindowResize,
  AudioContext,
} from "../util";

import { useSetRecoilState } from "recoil";
import { isLoginModalOpenState, isHomeModelLoaded } from "../atom";

import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RGBShiftShader } from "three/examples/jsm/shaders/RGBShiftShader";

const OBJECT_MAPPER = {
  0: "deceitfulMind",
  1: "treasureBowl",
  2: "threeHeadedDog",
  3: "pot",
  4: "escapePod",
  5: "weapon",
};

const Canvas = ({ entered }) => {
  const setIsLoginModalOpen = useSetRecoilState(isLoginModalOpenState);
  const setIsHomeModelLoaded = useSetRecoilState(isHomeModelLoaded);
  const history = useHistory();
  const canvasRef = useRef(null);
  const { explodedWindSound } = useContext(AudioContext);
  const draggingSound = useMemo(() => new BackgroundSound("drag"), []);
  // since the canvas loads early, the background sound may be set before user see the canvas.
  // so we play sound only after the entry effect has ended fully and `props.entered` the canvas.
  useEffect(() => {
    const canvas = canvasRef.current;
    // basics
    const shapeControllers = [];
    const activeObjectGroup = [];
    const mouse = new Vector2();
    const raycaster = new Raycaster();
    let animationFrameId;
    let scene;
    let camera;
    let cameraGroup;
    let renderer;
    let composer;
    let dragControls;

    // models
    let SpaceshipModel;
    let TrashModel;
    let SandModel = [];
    let ObjectGroupModel;
    let BgPlane;
    let KimController;

    // helpers
    let bones = [];
    let targetId = -1;
    let randomRotationDegrees = [];

    // counts
    let explodeTime = 0;
    let explodeRange = 0;
    let dragInfo = { id: -1, length: 0, strength: 0 };

    // flags
    let isExploded = false;

    init();

    function init() {
      scene = new Scene();
      scene.background = new Color(0xffffff);

      setVariables();
      createCamera();
      createLights();
      createBackground();
      loadModels();
      createRenderComposer();

      update();
    }

    function setVariables() {
      setExplodeRange();
      setRandomRotationDegrees();
    }

    function setExplodeRange() {
      explodeRange = Math.round(Math.random() * 22) + 3.9;
    }

    function setRandomRotationDegrees() {
      for (let i = 0; i < 5; i++) {
        const x = (Math.random() - 0.5) / 100;
        const y = (Math.random() - 0.5) / 100;
        const z = (Math.random() - 0.5) / 100;
        randomRotationDegrees[i] = { x, y, z };
      }
    }

    function createCamera() {
      camera = new PerspectiveCamera(
        70,
        window.innerWidth / window.innerHeight,
        1,
        5000
      );
      camera.targetPos = 0;

      cameraGroup = new Group();
      cameraGroup.attach(camera);
      cameraGroup.position.set(0, 200, 800);
      cameraGroup.rotation.x = -0.25;

      scene.add(cameraGroup);
    }

    function createLights() {
      const ambientLight = new AmbientLight(0xffffff, 1);

      const lightA = new SpotLight(0xffffff, 0.5);
      lightA.position.set(0, 1000, 0);
      lightA.shadow.camera.near = 0;
      lightA.shadow.camera.far = 4000;
      lightA.penumbra = 1;

      const lightB = new SpotLight(0xffeb82, 2);
      lightB.position.set(0, 0, -1000);
      lightB.shadow.camera.near = 0;
      lightB.shadow.camera.far = 4000;
      lightA.penumbra = 1;

      scene.add(ambientLight);
      scene.add(lightA);
      scene.add(lightB);
    }

    function createBackground() {
      const geometry = new PlaneGeometry(2000, 1000);
      const backgroundImage = new TextureLoader().load("/models/desert.jpg");
      const bgMaterial = new MeshBasicMaterial({
        color: 0xffffff,
        side: DoubleSide,
        map: backgroundImage,
      });

      BgPlane = new Mesh(geometry, bgMaterial);
      BgPlane.lookAt(cameraGroup.position);
      BgPlane.position.set(0, -500, -1800);
      BgPlane.scale.set(5, 5, 5);
      BgPlane.targetScale = 3.82;

      const kimGeometry = new SphereGeometry(85, 8, 8);
      const kimMaterial = new MeshPhongMaterial({ color: 0xd87c3f });
      KimController = new Mesh(kimGeometry, kimMaterial);

      KimController.position.set(280, -250, 0);
      KimController.name = "kim";
      KimController.nid = 5;
      KimController.visible = false;

      BgPlane.add(KimController);
      scene.add(BgPlane);
    }

    async function loadModels() {
      let loader = new FBXLoader();
      await loadSpaceship();
      await loadSand();
      setIsHomeModelLoaded(true);
      await loadObjectGroup();
      await loadTrash();

      // load SpaceShip
      function loadSpaceship() {
        return new Promise((resolve, _reject) => {
          loader.load(
            "/models/spaceship/model-spaceship.fbx",
            function (fbxObject) {
              SpaceshipModel = fbxObject;
              SpaceshipModel.scale.set(100, 100, 100);
              setSpaceshipMaterial();
              makeSpaceShipDraggable();
              scene.add(SpaceshipModel);
              resolve();

              function setSpaceshipMaterial() {
                SpaceshipModel.children[0].material[0].side = DoubleSide;
                SpaceshipModel.children[0].material[0].shininess = 0;
                SpaceshipModel.children[0].material[1].side = DoubleSide;
                SpaceshipModel.children[0].material[1].shininess = 5;
                SpaceshipModel.children[0].material[2].side = DoubleSide;
                SpaceshipModel.children[0].material[3].shininess = 5;
                SpaceshipModel.children[0].material[2].specular = new Color(
                  0xffffff
                );
                SpaceshipModel.children[0].material[3].side = DoubleSide;
                SpaceshipModel.children[0].material[2].shininess = 5;
                SpaceshipModel.children[0].material[4].side = DoubleSide;
                SpaceshipModel.children[0].material[5].side = DoubleSide;
                SpaceshipModel.children[0].material[6].side = DoubleSide;
                SpaceshipModel.children[0].material[7].emissiveIntensity = 5;
                SpaceshipModel.children[0].material[8].shininess = 50;
                SpaceshipModel.children[0].material[8].specular = new Color(
                  0xffffff
                );
              }

              function makeSpaceShipDraggable() {
                const BONE_ID = 1;
                bones = fbxObject.children[BONE_ID].children.map((bone) => {
                  return bone;
                });

                //TODO: use reduce
                const geometry = new SphereGeometry(2, 8, 8);
                for (let i = 0; i < bones.length; i++) {
                  const shapeController = new Mesh(geometry);
                  shapeController.visible = false;

                  SpaceshipModel.children[BONE_ID].add(shapeController);

                  shapeController.position.copy(bones[i].position);
                  shapeController.oldPos = shapeController.position.clone();
                  shapeController.targetPos = shapeController.position;
                  shapeController.startDrag = false;
                  shapeController.startDragPos = new Vector3();
                  shapeController.nid = i;

                  shapeControllers.push(shapeController);
                }
                shapeControllers.push(KimController);

                // TODO: move to end of useEffect
                dragControls = new DragControls(
                  [...shapeControllers],
                  camera,
                  renderer.domElement
                );
                dragControls.addEventListener("dragstart", onDragstart);
                dragControls.addEventListener("drag", onDragging);
                dragControls.addEventListener("dragend", onDragEnd);

                // TODO: refactor onDragStart
                function onDragstart(event) {
                  if (event.object.name === "kim") {
                    event.object.position.set(280, -250, 0);
                    onClickTargetObject(5);
                  } else {
                    draggingSound.play();
                  }
                }

                // TODO: refactor onDragging
                function onDragging(event) {
                  const shapeController = event.object;
                  if (shapeController.name === "kim") {
                    shapeController.position.set(280, -250, 0);
                  } else {
                    if (!shapeController.startDrag) {
                      const currentPosition = {
                        x: shapeController.position.x,
                        y: shapeController.position.y,
                        z: shapeController.position.z,
                      };
                      shapeController.startDragPos.set(
                        currentPosition.x,
                        currentPosition.y,
                        currentPosition.z
                      );
                      shapeController.startDrag = true;
                    }
                    const draggedDistance =
                      shapeController.startDragPos.distanceTo(
                        shapeController.position
                      );
                    dragInfo.length = Math.min(draggedDistance, 4);
                    dragInfo.id = shapeController.nid;
                    // dragBone(dragInfo.id, dragInfo.length);
                  }
                }

                // function dragBone(targetId, _length) {
                //   console.log("抓取骨頭:" + targetId + ",長度: " + _length);
                // }

                // TODO: refactor onDragEnd
                function onDragEnd(event) {
                  const shapeController = event.object;
                  if (shapeController.name !== "kim") {
                    draggingSound.stop();
                    let normalizedPosition = new Vector3(
                      shapeController.position.x - shapeController.oldPos.x,
                      shapeController.position.y - shapeController.oldPos.y,
                      shapeController.position.z - shapeController.oldPos.z
                    ).normalize();

                    shapeController.targetPos = new Vector3();
                    const calculatedPosition = {
                      x:
                        shapeController.position.x -
                        normalizedPosition.x * dragInfo.length * 0.3,
                      y:
                        shapeController.position.y -
                        normalizedPosition.y * dragInfo.length * 0.3,
                      z:
                        shapeController.position.z -
                        normalizedPosition.z * dragInfo.length * 0.3,
                    };
                    shapeController.targetPos.set(
                      calculatedPosition.x,
                      calculatedPosition.y,
                      calculatedPosition.z
                    );
                    shapeController.oldPos = shapeController.targetPos.clone();

                    dragInfo.strength += dragInfo.length;
                  }

                  resetDragSettings();
                  shapeController.startDrag = false;
                }

                function resetDragSettings() {
                  dragInfo.id = -1;
                  dragInfo.length = 0;
                }
              }
            }
          );
        });
      }

      // load Sand
      function loadSand() {
        return new Promise((resolve, _reject) => {
          loader.load(
            "/models/sand/model-flying-sand.fbx",
            function (fbxObject) {
              makeSandModel();
              setSandModelMaterials();
              setSandModelSettings();
              scene.add(SandModel[0]);
              scene.add(SandModel[1]);
              scene.add(SandModel[2]);
              scene.add(SandModel[3]);
              resolve();

              function makeSandModel() {
                fbxObject.scale.set(100, 100, 100);
                SandModel[0] = fbxObject;
                SandModel[1] = fbxObject.clone();
                SandModel[2] = fbxObject.clone();
                SandModel[3] = fbxObject.clone();
              }

              function setSandModelMaterials() {
                const sandTexture1 = new TextureLoader().load(
                  "/models/sand/texture-sand1.jpg"
                );
                const sandTexture2 = new TextureLoader().load(
                  "/models/sand/texture-sand2.jpg"
                );

                const material1 = new MeshPhongMaterial({
                  color: 0xd87c3f,
                  transparent: true,
                });
                material1.side = DoubleSide;
                material1.alphaMap = sandTexture1;
                material1.shininess = 0;

                const material2 = new MeshPhongMaterial({
                  color: 0xd87c3f,
                  transparent: true,
                });
                material2.side = DoubleSide;
                material2.alphaMap = sandTexture2;
                material2.shininess = 0;

                const MESH_ID = 0;
                SandModel[0].children[MESH_ID].material = material1;
                SandModel[1].children[MESH_ID].material = material1;
                SandModel[2].children[MESH_ID].material = material2;
                SandModel[3].children[MESH_ID].material = material2;
              }

              function setSandModelSettings() {
                SandModel[0].rotation.x -= 0.01;
                SandModel[1].rotation.x -= 0.01;
                SandModel[1].rotation.z += 0.01;
                SandModel[2].rotation.x += 0.01;
                SandModel[2].rotation.z -= 0.01;
                SandModel[3].rotation.x += 0.01;
                SandModel[3].rotation.z += 0.01;
              }
            }
          );
        });
      }

      // load Trash
      function loadTrash() {
        return new Promise((resolve, _reject) => {
          loader.load("/models/trash/model-trash.fbx", function (fbxObject) {
            TrashModel = fbxObject;
            TrashModel.scale.set(0, 0, 0);
            setTrashModelMaterials();
            setTrashModelSettings();
            scene.add(TrashModel);
            resolve();

            function setTrashModelMaterials() {
              const MESH_ID = 0;
              TrashModel.children[MESH_ID].material.side = DoubleSide;
            }

            function setTrashModelSettings() {
              TrashModel.targetScale = 0;
              TrashModel.position.z = -450;
              TrashModel.position.y = -150;
            }
          });
        });
      }

      // load 5 shapeControllers
      function loadObjectGroup() {
        return new Promise((resolve, _reject) => {
          loader.load(
            "/models/object-group/model-object-group.fbx",
            function (fbxObject) {
              ObjectGroupModel = fbxObject;
              ObjectGroupModel.scale.set(0, 0, 0);
              setObjectGroupModelMaterials();
              setObjectGroupModelSettings();
              scene.add(ObjectGroupModel);
              resolve();

              function setObjectGroupModelMaterials() {
                for (let i = 0; i < 5; i++) {
                  ObjectGroupModel.children[i].material.side = DoubleSide;
                  ObjectGroupModel.children[i].material.shininess = 0;
                  ObjectGroupModel.children[i].material.color = new Color(
                    0xffffff
                  );
                  ObjectGroupModel.children[i].material.emissive = new Color(
                    0xffffff
                  );
                  ObjectGroupModel.children[i].material.emissiveMap =
                    ObjectGroupModel.children[i].material.map;

                  const geometry = new SphereGeometry(0.5, 16, 16);
                  let helperMesh = new Mesh(geometry);
                  helperMesh.visible = false;
                  helperMesh.nid =
                    parseInt(ObjectGroupModel.children[i].name) - 1;

                  activeObjectGroup.push(helperMesh);
                  ObjectGroupModel.children[i].add(helperMesh);
                }
              }

              function setObjectGroupModelSettings() {
                ObjectGroupModel.targetScale = 0;
                ObjectGroupModel.position.z = -450;
                ObjectGroupModel.position.y = -160;
              }
            }
          );
        });
      }
    }

    function createRenderComposer() {
      createRenderer();
      setComposer();

      function createRenderer() {
        renderer = new WebGLRenderer({ antialias: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.domElement.style.display = "block";

        canvas.appendChild(renderer.domElement);
      }

      function setComposer() {
        const basePass = new RenderPass(scene, camera);
        const bloomPass = new UnrealBloomPass(
          new Vector2(window.innerWidth, window.innerHeight),
          1.5,
          0.4,
          0.85
        );
        const rgbShift = new ShaderPass(RGBShiftShader);
        bloomPass.threshold = 0.95;
        bloomPass.strength = 0.5;
        bloomPass.radius = 0.5;
        rgbShift.uniforms.amount.value = 0.001;

        composer = new EffectComposer(renderer);
        composer.addPass(basePass);
        composer.addPass(rgbShift);
        // composer.addPass(bloomPass);
      }
    }

    function onPointerMove(event) {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    }

    function onClick(event) {
      //event.preventDefault(); // unable to click footer
      if (isExploded) {
        onClickTargetObject(targetId);
      }
    }

    function updateShapeControllerAfterExplosion() {
      for (let i = 0; i < shapeControllers.length - 1; i++) {
        shapeControllers[i].position.x = shapeControllers[i].position.x * 0.2;
        shapeControllers[i].position.y = shapeControllers[i].position.y * 0.2;
        shapeControllers[i].position.z = shapeControllers[i].position.z * 0.2;
        bones[i].position.copy(shapeControllers[i].position);
      }

      shapeControllers[0].targetPos.x = (1 + Math.random()) * 20;
      shapeControllers[0].targetPos.y = (1 + Math.random()) * 20;
      shapeControllers[1].targetPos.x = (1 + Math.random()) * 20;
      shapeControllers[1].targetPos.y = -(1 + Math.random()) * 20;
      shapeControllers[2].targetPos.x = -(1 + Math.random()) * 20;
      shapeControllers[2].targetPos.y = (1 + Math.random()) * 20;
      shapeControllers[3].targetPos.x = -(1 + Math.random()) * 20;
      shapeControllers[3].targetPos.y = -(1 + Math.random()) * 20;
      shapeControllers[4].targetPos.x = (Math.random() * 2 - 1) * 30;
      shapeControllers[4].targetPos.y = (Math.random() * 2 - 1) * 30;
      shapeControllers[5].targetPos.x = (Math.random() * 2 - 1) * 30;
      shapeControllers[5].targetPos.y = (Math.random() * 2 - 1) * 30;
    }

    function updateBgPlaneAfterExplosion() {
      BgPlane.position.set(-200, -500, -1800);
    }

    function update() {
      //Animation
      if (SpaceshipModel && SandModel[0] && TrashModel && ObjectGroupModel) {
        updateSandModelAnimation();
        updateSpaceshipModelAnimation();
        updateTrashModelAnimation();
        updateObjectGroupAnimation();
        updateShapeControllerAnimation();
        updateBgPlaneAnimation();
        updateCameraAnimation();
      }

      // TODO: refactor After Explode
      if (isExploded) {
        raycaster.setFromCamera(mouse, camera);
        const intersections = raycaster.intersectObjects(activeObjectGroup);
        if (intersections.length > 0) {
          let hitObject = intersections[0].object;
          targetId = hitObject.nid;
          document.body.style.cursor = "pointer";
        } else {
          targetId = -1;
          document.body.style.cursor = "auto";
        }
      }

      explode();
      setTimeout(function () {
        animationFrameId = window.requestAnimationFrame(update);
      }, 1000 / 40); // 40 fps
      composer.render();
    }

    function updateSandModelAnimation() {
      SandModel[0].rotation.y -= Math.random() * 0.3;
      SandModel[1].rotation.y += Math.random() * 0.4;
      SandModel[2].rotation.y += Math.random() * 0.2;
      SandModel[3].rotation.y -= Math.random() * 0.3;
    }

    function updateSpaceshipModelAnimation() {
      SpaceshipModel.rotation.y += 0.005;
      SpaceshipModel.children[0].material[7].map.offset.x += 0.008;
    }

    function updateTrashModelAnimation() {
      TrashModel.rotation.y += 0.01;

      const trashScale =
        TrashModel.scale.x - (TrashModel.scale.x - TrashModel.targetScale) / 15;
      TrashModel.scale.set(trashScale, trashScale, trashScale);
    }

    function updateObjectGroupAnimation() {
      ObjectGroupModel.rotation.y += 0.005;

      for (let i = 0; i < 5; i++) {
        ObjectGroupModel.children[i].rotation.x += randomRotationDegrees[i].x;
        ObjectGroupModel.children[i].rotation.y += randomRotationDegrees[i].y;
        ObjectGroupModel.children[i].rotation.z += randomRotationDegrees[i].z;
      }

      const objectGroupScale =
        ObjectGroupModel.scale.x -
        (ObjectGroupModel.scale.x - ObjectGroupModel.targetScale) / 15;
      ObjectGroupModel.scale.set(
        objectGroupScale,
        objectGroupScale,
        objectGroupScale
      );
    }

    // TODO: refactor position update
    function updateShapeControllerAnimation() {
      for (let i = 0; i < shapeControllers.length - 1; i++) {
        let shapeController = shapeControllers[i];
        let targetPos = shapeController.targetPos;

        let speed = isExploded ? 20 : 30;
        const calculatedPosition = {
          x:
            shapeController.position.x -
            (shapeController.position.x - targetPos.x) / speed,
          y:
            shapeController.position.y -
            (shapeController.position.y - targetPos.y) / speed,
          z:
            shapeController.position.z -
            (shapeController.position.z - targetPos.z) / speed,
        };
        shapeController.position.set(
          calculatedPosition.x,
          calculatedPosition.y,
          calculatedPosition.z
        );

        if (isExploded) {
          explodeTime++;

          TrashModel.targetScale = 200;
          ObjectGroupModel.targetScale = 200;
          BgPlane.targetScale = 4.2;
          camera.targetPos = -500;

          if (explodeTime > 30) {
            targetPos.x = 0;
            targetPos.y = 0;
            targetPos.z = -100;
            TrashModel.targetScale = 80;
          }
        }
        bones[i].position.copy(shapeControllers[i].position);
      }
    }

    function updateBgPlaneAnimation() {
      const bgPlaneScale =
        BgPlane.scale.x - (BgPlane.scale.x - BgPlane.targetScale) / 15;
      BgPlane.scale.set(bgPlaneScale, bgPlaneScale, bgPlaneScale);
    }

    function updateCameraAnimation() {
      camera.position.z =
        camera.position.z - (camera.position.z - camera.targetPos) / 15;
    }

    function explode() {
      if (!isExploded && dragInfo.strength >= explodeRange) {
        updateShapeControllerAfterExplosion();
        updateBgPlaneAfterExplosion();

        dragControls.dispose();
        activeObjectGroup.push(KimController);
        explodeSound();

        isExploded = true;
      }
    }

    function onClickTargetObject(targetId) {
      const targetObject = OBJECT_MAPPER[targetId];
      playSoundByKey(targetObject);
      switch (targetObject) {
        case "escapePod":
          const token = getToken();
          if (!token) {
            setIsLoginModalOpen(true);
          } else {
            history.push("/vip");
          }
          break;
        case "deceitfulMind":
          history.push("/discography");
          break;
        case "treasureBowl":
          history.push("/history");
          break;
        case "threeHeadedDog":
          history.push("/about");
          break;
        case "pot":
          history.push("/video");
          break;
        default:
          break;
      }
    }

    function explodeSound() {
      playSoundByKey("explosion");
      explodedWindSound.play();
    }

    window.addEventListener("resize", () =>
      onWindowResize(camera, renderer, composer)
    );
    window.addEventListener("pointermove", onPointerMove);
    window.addEventListener("click", onClick);
    return () => {
      window.cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", onWindowResize);
      window.removeEventListener("pointermove", onPointerMove);
      window.removeEventListener("click", onClick);
    };
  }, [
    draggingSound,
    explodedWindSound,
    history,
    setIsHomeModelLoaded,
    setIsLoginModalOpen,
  ]);

  return <div className="home-canvas" ref={canvasRef} />;
};

export default Canvas;
