import { useState, useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import CloseButton from "../common/CloseButton";
import ProfileForm from "./ProfileForm";
import ProfileView from "./ProfileView";
import { isProfileModalOpenState } from "../../atom";
import {
  desktopProfileModalStyle,
  mobileProfileModalStyle,
} from "../../constant";
import { isMobile } from "react-device-detect";
import {
  useAuthContext,
  getUserName,
  setUserName,
  apiCall,
  toastDark,
} from "../../util";

const ProfileModal = () => {
  const { t } = useTranslation();
  const [isProfileModalOpen, setIsProfileModalOpen] = useRecoilState(
    isProfileModalOpenState
  );
  const [isEditing, setIsEditing] = useState(false);
  const modalStyle = useMemo(() => {
    return isMobile ? mobileProfileModalStyle : desktopProfileModalStyle;
  }, []);
  const history = useHistory();
  const userName = getUserName();
  const { logout } = useAuthContext();
  const [profile, setProfile] = useState({});
  useEffect(() => {
    if (!isProfileModalOpen) return;
    if (!userName) {
      history.push("/");
      toastDark("請登入😠");
    } else {
      const onSuccess = (data) => {
        setProfile(data);
      };
      const onError = (err) => {
        if (err.status === 401) {
          const callback = () => toastDark("連線逾時，請重新登入");
          logout(callback);
        } else {
          toastDark("資料載入失敗😠");
        }
      };
      apiCall({
        url: `v2/users/me`,
        method: "get",
        onSuccess,
        onError,
      });
    }
    // isEditing for re-render modal info
  }, [isProfileModalOpen, isEditing, history, logout, userName]);

  const submit = useCallback(
    (data) => {
      const onSuccess = () => {
        setIsEditing(false);
        setUserName(data.nickName);
        toastDark("修改成功🙌🏻");
      };
      const onError = (err) => {
        if (err.status === 401) {
          const callback = () => toastDark("連線逾時，請重新登入");
          logout(callback);
        } else {
          toastDark("資料修改失敗😠");
        }
      };
      apiCall({
        url: `v2/users/me`,
        method: "put",
        data,
        onSuccess,
        onError,
      });
    },
    [logout]
  );
  const onEdit = () => {
    setIsEditing(true);
  };
  return (
    <Modal style={modalStyle} isOpen={isProfileModalOpen}>
      <div className="flex justify-end">
        <CloseButton onClose={() => setIsProfileModalOpen(false)} />
      </div>
      {!isEditing && (
        <p className="cursor-pointer text-right" onClick={onEdit}>
          <img
            className="inline-block pr-1"
            src="/img/icon/operation/edit.svg"
            alt="edit icon"
          />
          {t("edit")}
        </p>
      )}
      <div className="profile-modal-header flex justify-between items-center">
        <div className="profile-modal-title">Hi, {userName}</div>
      </div>
      {isEditing ? (
        <ProfileForm profile={profile} submit={submit} submitText={t("save")} />
      ) : (
        <ProfileView profile={profile} />
      )}
    </Modal>
  );
};
export default ProfileModal;
