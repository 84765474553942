export const membersInfos = [
  {
    key: "gobo",
    name: "狗柏",
    intro:
      "婷文，退役軍醫，其家族為碩果僅存的沙漠紅蟳養殖戶，因患嚴重創傷後壓力症候群，於集團內成立秘密子組織，開發紅蟳基因合成女兵，但也導致其後代智商降低至紅蟳的水準。", // TODO: correct member intro
    skill: "隨身攜帶證件照，提升自己奉公守法的態度。",
    equip:
      "左右各有一隻蠟燭，左邊亮表示可以色色，右邊亮表示色即是空，兩隻同時閃爍表示倒數三秒進入聖人模式。",
  },
  {
    key: "hsiu",
    name: "修齊",
    intro:
      "婷文，退役軍醫，其家族為碩果僅存的沙漠紅蟳養殖戶，因患嚴重創傷後壓力症候群，於集團內成立秘密子組織，開發紅蟳基因合成女兵，但也導致其後代智商降低至紅蟳的水準。", // TODO: correct member intro
    skill: "任何壺狀物品經由搓揉都會冒出大量水蒸氣，其故鄉視為奇蹟、精靈之力。",
    equip: "大便翅膀，使用一次可獲得飛行能力，下次使用前需補充。",
  },
  {
    key: "luis",
    name: "冠佑",
    intro:
      "婷文，退役軍醫，其家族為碩果僅存的沙漠紅蟳養殖戶，因患嚴重創傷後壓力症候群，於集團內成立秘密子組織，開發紅蟳基因合成女兵，但也導致其後代智商降低至紅蟳的水準。", // TODO: correct member intro
    skill:
      "由於家徽物種是歸屬於甲殼亞門或螯肢亞門尚有爭議，得到被節肢動物喜好的天賦。",
    equip: "家族家徽據說是螃蟹",
  },
  {
    key: "wen",
    name: "婷文",
    intro:
      "婷文，退役軍醫，其家族為碩果僅存的沙漠紅蟳養殖戶，因患嚴重創傷後壓力症候群，於集團內成立秘密子組織，開發紅蟳基因合成女兵，但也導致其後代智商降低至紅蟳的水準。",
    skill:
      "血剩1D的時候，有機率施展集體復活術。真實面貌是時空魔法，將人在不同時間線重來，導致分裂成鐵時空、銀時空等。",
    equip: "一隻特別可愛的狗",
  },
  {
    key: "kenny",
    name: "鍾錡",
    intro:
      "婷文，退役軍醫，其家族為碩果僅存的沙漠紅蟳養殖戶，因患嚴重創傷後壓力症候群，於集團內成立秘密子組織，開發紅蟳基因合成女兵，但也導致其後代智商降低至紅蟳的水準。", // TODO: correct member intro
    skill: "有新想法就會亮的燈，常常因此被看穿內在，是客座精靈天生就有的角。",
    equip: "在拍攝現場偶然得到的書",
  },
];

export const memberInfoMap = membersInfos.reduce((acc, cur) => {
  return (acc = {
    ...acc,
    [cur.key]: {
      name: cur.name,
      intro: cur.intro,
      skill: cur.skill,
      equip: cur.equip,
    },
  });
}, {});
