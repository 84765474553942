import { useCallback, useMemo, memo } from "react";
import { useRecoilState } from "recoil";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../util";
import { isLoginModalOpenState } from "../../atom";
import { mobileModalStyle, desktopModalStyle } from "../../constant";
import Modal from "react-modal";
import CloseButton from "../common/CloseButton";
import FacebookLoginButton from "./FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";
import PolicyRow from "./PolicyRow";

Modal.setAppElement("#root");

const LoginModal = memo(() => {
  const { t } = useTranslation();
  const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(
    isLoginModalOpenState
  );
  const { login } = useAuthContext();

  const onLogin = useCallback(
    (type, accessToken, name) => {
      const callback = () => {
        setIsLoginModalOpen(false);
      };
      login(type, accessToken, name, callback);
    },
    [login, setIsLoginModalOpen]
  );

  const onModalClose = useCallback(() => {
    setIsLoginModalOpen(false);
  }, [setIsLoginModalOpen]);

  const modalStyle = useMemo(() => {
    return isMobile ? mobileModalStyle : desktopModalStyle;
  }, []);

  const closeButtonPosition = useMemo(() => {
    return isMobile ? "top-8 right-8" : "top-6 right-10";
  }, []);

  return (
    <Modal style={modalStyle} isOpen={isLoginModalOpen}>
      <div>
        <div className={`absolute ${closeButtonPosition}`}>
          <CloseButton onClose={onModalClose} />
        </div>
        <div className="pt-2 font-medium modal-title text-center">
          {t("signin_signup")}
        </div>
      </div>
      <p className="text-sm text-center signup-header mt-5">
        {t("signin_social")}
      </p>
      <div className="flex flex-col my-5">
        <GoogleLoginButton login={onLogin} />
        <FacebookLoginButton login={onLogin} />
      </div>
      <PolicyRow closeModal={onModalClose} />
    </Modal>
  );
});

export default LoginModal;
