import { Link } from "react-router-dom";
import { SpriteAnimator } from "react-sprite-animator";

const ErrorView = () => {
  return (
    <div className="h-screen flex justify-center items-center flex-col text-white">
      <SpriteAnimator
        className="mb-5"
        sprite="/img/tv/tv.png"
        width={78}
        height={90}
        fps={8}
        frameCount={26}
      />
      <h3 className="text-lg">oops！有錯誤發生囉！</h3>
      <p>
        請{" "}
        <Link className="underline" to="/">
          回到首頁
        </Link>{" "}
        再試一次
      </p>
    </div>
  );
};

export default ErrorView;
