import { Vector3, AnimationMixer } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import { Instrument } from "./instrument";
import { instrumentsInfo3 } from "../../constant";
import { isMobile } from "react-device-detect";

const setting = {
  position: new Vector3(4, -1, 10),
  scale: isMobile ? new Vector3(5, 5, 5) : new Vector3(8, 8, 8),
};

class Instrument3 extends Instrument {
  constructor() {
    super(3, ["instrument10"]);
    this.instrumentsInfo = instrumentsInfo3;
  }
  load(scene) {
    const loader = new GLTFLoader();
    loader.load(`/models/instruments/instrument${this.id}.glb`, (gltf) => {
      this.gltf = gltf;
      this.gltf.scene.traverse((child) => {
        if (child.name === "Button_range") {
          child.visible = false;
        }
        if (child.name === "body_low_1") {
          this.body = child;
        }
      });
      this.gltf.scene.name = `Instrument_model${this.id}`;
      this.gltf.scene.scale.copy(setting.scale);
      this.animationMixer = new AnimationMixer(this.body);
      this.animations = this.gltf.animations[0];
      this.objects = this.gltf.scene.children[2].children;
      this.setAxisCenter(this.box, this.gltf.scene);

      scene.add(this.gltf.scene);
    });
  }
  click() {
    if (!this.gltf) return;
    const intersects = this.raycaster.intersectObjects(this.objects, true);
    if (intersects.length) {
      const name = intersects[0].object.name;
      if (this.instrumentsInfo[name]) {
        this.target = name;
        this.playSound();
        this.playAnimation();
      }
    }
  }
  hover() {
    if (!this.gltf) return;
    const intersects = this.raycaster.intersectObjects(this.objects, true);
    if (intersects.length) {
      const name = intersects[0].object.name;
      if (this.instrumentsInfo[name]) {
        document.body.style.cursor = "pointer";
      } else {
        document.body.style.cursor = "auto";
      }
    }
  }
  playAnimation() {
    this.animationMixer = new AnimationMixer(this.body);
    this.animationAction = this.animationMixer.clipAction(this.animations);
    this.animationAction.play();
  }
  stop() {
    if (this.animationAction) {
      this.stopAnimation();
    }
    this.stopSound();
    this.target = null;
  }
}

export default Instrument3;
